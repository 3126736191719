import React, { useEffect, useState } from "react";
import { Col, Row, CardText, Button } from "reactstrap";

import shallow from "zustand/shallow";
import NumberFormat from "react-number-format";
import Card from "reactstrap/es/Card";
import CardBody from "reactstrap/es/CardBody";
import useCurrentAccountStore from "./AccountStore";
import {
	CurrencyDisplayElement,
	DateDisplayElement,
} from "../features/deal/components/DisplayElement";
import ReactTable from "../components/tables/ReactTable";
import {
	confirmDeleteAlert,
	deleteFailAlert,
	deleteSuccessAlert,
} from "../utils/alertUtils";
import { deleteLateFee } from "./AccountAPI";
import { showApiError } from "../utils/errorRoutingUtils";
import ChildrenModal from "../components/modals/ChildrenModal";
import AccountHook from "./AccountHook";
import AccountLateForm from "./AccountlateForm";

const AccountlateFeesTable = () => {
	const [isUpdated, setIsUpdated] = useState(false);
	const save = AccountHook();
	const { account, lateFees, editLateFees } = useCurrentAccountStore(
		(state) => ({
			lateFees: state.lateFees,
			editLateFees: state.editLateFees,
			account: state.account,
		}),
		shallow
	);

	const [currentIndex, setCurrentIndex] = useState(lateFees.length);

	const [showAdd, setShowAdd] = useState(false);
	const toggle = () => setShowAdd(!showAdd);
	const toggleModal = (currentID, currentIndex) => {
		confirmDeleteAlert(removelate, currentID, currentIndex);
	};

	const removelate = (ID, index) => {
		console.log("Removing late fee with ID: " + ID);
		if (ID) {
			deleteLateFee(ID).then(
				(response) => {
					let obj = [];
					if (ID !== null) {
						obj = lateFees.filter((item) => item.id !== ID);
					} else {
						obj = lateFees.filter((item) => item.currentIndex !== index);
					}
					editLateFees(obj);
					setCurrentIndex(obj.length);
					setIsUpdated(true);

					deleteSuccessAlert();
				},
				(err) => {
					if (!err.isGeneralError) {
						showApiError(err, deleteFailAlert);
					}
				}
			);
		}
	};
	useEffect(() => {
		if (isUpdated) {
			save();
			setIsUpdated(false);
		}
		// eslint-disable-next-line
	}, [isUpdated]);
	const columns = React.useMemo(
		() => [
			{
				Header: "#",
				accessor: (row, index) => index + 1,
				style: { width: "20px" },
			},
			{
				Header: "Date",
				accessor: "dateEntered",
				Cell: ({ value }) => <DateDisplayElement val={value} />,
			},
			{
				Header: "Amount",
				accessor: "amount",
				Cell: ({ value }) => <CurrencyDisplayElement val={value} />,
			},
			{
				Header: "Actions",
				accessor: (row, index) => ({ ...row, index }), // Include the entire row and index
				disableFilters: true,
				Cell: ({ value }) => (
					<div className="d-flex justify-content-center">
						{account.status === 0 && (
							<div
								className="btn btn-md btn-primary"
								onClick={() => {
									setCurrentIndex(value.index);
									toggle();
								}}
							>
								<i className="nc-icon nc-align-center"></i>
								<span className="noMobile">Edit</span>
							</div>
						)}
						{account.status === 0 && (
							<div
								className="btn btn-md btn-link btn-link-danger text-danger ml-2"
								onClick={() => {
									toggleModal(value.id, value.index);
								}}
							>
								X
							</div>
						)}
					</div>
				),
			},
		],
		// eslint-disable-next-line
		[]
	);

	return (
		<>
			<ChildrenModal
				size="lg"
				modal={showAdd}
				toggle={toggle}
				modalTitle="late"
			>
				<AccountLateForm
					currentIndex={currentIndex}
					setCurrentIndex={setCurrentIndex}
					toggle={toggle}
				/>
			</ChildrenModal>
			<Card>
				<CardBody>
					<div>
						<ReactTable data={lateFees} columns={columns} />
					</div>
				</CardBody>
			</Card>

			<Card>
				<CardBody>
					<Row>
						<Col sm="3" className="text-center">
							{account.status === 0 && (
								<Button
									className="btn btn-primary btn-md"
									onClick={() => {
										setCurrentIndex(lateFees.length);
										toggle();
									}}
								>
									<i className="nc-icon nc-simple-add" />
									Add Late Fee
								</Button>
							)}
						</Col>
						<Col sm="3">
							<CardText tag="h3" className="text-center">
								lateFees
							</CardText>
						</Col>
						<Col sm="3">
							<CardText tag="h3" className="text-center">
								<NumberFormat
									value={account.totalLateFees}
									thousandSeparator={true}
									decimalScale={2}
									fixedDecimalScale={true}
									prefix={"$"}
									isNumericString={true}
									readOnly={true}
									displayType="text"
								/>
							</CardText>
						</Col>
					</Row>
				</CardBody>
			</Card>
		</>
	);
};
export default AccountlateFeesTable;

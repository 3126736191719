import dayjs from "dayjs";
import InputElement, {
	CurrencyInput,
} from "features/forms/components/InputElement";
import React, { useEffect, useState } from "react";
import { Button, Form } from "reactstrap";
import { useFromUser } from "stores/LocalStorageHelper";

import { yupResolver } from "@hookform/resolvers";
import InputWrapper from "features/forms/components/InputWrapper";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { setLocale } from "yup";
import shallow from "zustand/shallow";
import { SelectorElement } from "../features/forms/components/InputElement";
import AccountHook from "./AccountHook";
import useCurrentAccountStore from "./AccountStore";

const AccountAdjustmentForm = (props) => {
	const save = AccountHook();
	const { currentIndex, toggle } = props;
	const { adjustments, editAdjustments, account } = useCurrentAccountStore(
		(state) => state,
		shallow
	);
	const today = dayjs().format("YYYY-MM-DD");
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");

	const [amount, setAmount] = useState(0);
	const [cost, setCost] = useState(0);
	const [date, setdate] = useState(today);
	const [purchasedFrom, setPurchasedFrom] = useState("");
	const [roNumber, setRoNumber] = useState("");
	const [method, setMethod] = useState("Debit");
	const [checkNum, setCheckNum] = useState("");
	const [description, setDescription] = useState("");

	const [formTitle, setFormTitle] = useState([]);
	useEffect(() => {
		if (currentIndex < adjustments.length) {
			setFormTitle("Edit Adjustment ");
			setdate(adjustments[currentIndex]?.date || "");
			setAmount(adjustments[currentIndex]?.amount || "");
			setPurchasedFrom(adjustments[currentIndex]?.purchasedFrom || "");
			setRoNumber(adjustments[currentIndex]?.roNumber || "");
			setCheckNum(adjustments[currentIndex]?.checkNum);
			setMethod(adjustments[currentIndex]?.method);
			setDescription(adjustments[currentIndex]?.description);
		} else {
			setFormTitle("Add Adjustment");
		}
		// eslint-disable-next-line
	}, [currentIndex]);

	const addAdjustment = () => {
		const getID = () => {
			if (adjustments[currentIndex] === undefined) {
				return null;
			} else if (adjustments[currentIndex].id === undefined) {
				return null;
			} else {
				return adjustments[currentIndex].id;
			}
		};
		const ID = getID();
		const newAdj = {
			acctRecnum: account.id,
			acctNum: account.acctNum,
			amount,
			cost,
			date,
			purchasedFrom,
			roNumber,
			checkNum,
			method,
			description,
			currentIndex,
			createdOn: today,
			dealerID,
			locationID,
			id: ID,
		};

		const adjustmentList = () => {
			let obj = adjustments;
			obj[currentIndex] = newAdj;
			return obj;
		};
		const newAdjustments = adjustmentList();
		editAdjustments(newAdjustments);
		save();
		toggle();
	};

	// Define custom error messages for different error types
	setLocale({
		mixed: {
			required: "Required",
		},
		string: {
			// eslint-disable-next-line
			max: "Must be ${max} characters or less",
			length: "Required format is YYYY/MM/DD",
		},
	});

	// Define rules for each input field
	const schema = yup.object().shape({});

	// Define form validation parameters
	const methods = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});
	return (
		<>
			<FormProvider className="" {...methods}>
				<Form>
					<InputWrapper
						formTitle={formTitle}
						inputComponents={[
							<InputElement
								{...methods}
								readOnly={!account.status === 0}
								value={date}
								onChange={(e) => {
									setdate(e.target.value);
								}}
								name="date"
								label="Date"
								type="date"
							/>,
							<SelectorElement
								{...methods}
								readOnly={!account.status === 0}
								name="method"
								label="Methods"
								value={method}
								onChange={(e) => setMethod(e.target.value)}
								options={[
									{ value: "Debit", label: "Debit" },
									{ value: "Credit", label: "Credit" },
								]}
							/>,
							<CurrencyInput
								disableValidation
								readOnly={!account.status === 0}
								value={amount}
								onChange={setAmount}
								name="amount"
								label="Amount"
								type="number"
							/>,

							<CurrencyInput
								disableValidation
								readOnly={!account.status === 0}
								value={cost}
								onChange={setCost}
								name="cost"
								label="Cost"
								type="number"
							/>,

							<InputElement
								{...methods}
								readOnly={!account.status === 0}
								value={purchasedFrom}
								onChange={(e) => {
									setPurchasedFrom(e.target.value);
								}}
								name="purchasedFrom"
								label="Purchased From"
								type="text"
							/>,
							<InputElement
								{...methods}
								readOnly={!account.status === 0}
								value={roNumber}
								onChange={(e) => {
									setRoNumber(e.target.value);
								}}
								name="roNumber"
								label="RO Number"
								type="text"
							/>,
							<InputElement
								{...methods}
								readOnly={!account.status === 0}
								value={checkNum}
								onChange={(e) => {
									setCheckNum(e.target.value);
								}}
								name="checkNum"
								label="check No"
								type="text"
							/>,
							<InputElement
								{...methods}
								value={description}
								onChange={(e) => {
									setDescription(e.target.value);
								}}
								name="description"
								label="Description"
								type="text"
							/>,
						]}
						buttons={
							<div style={{ display: "flex", justifyContent: "center" }}>
								<Button
									active={account.status === 0}
									color="primary"
									onClick={methods.handleSubmit(addAdjustment)}
								>
									Save
								</Button>
							</div>
						}
					/>
				</Form>
			</FormProvider>
		</>
	);
};

export default AccountAdjustmentForm;

import React, { useEffect } from "react";
import { Form } from "reactstrap";

import shallow from "zustand/shallow";
import { useFormContext } from "react-hook-form";
import useCurrentAccountStore from "./AccountStore";
import InputWrapper from "../features/forms/components/InputWrapper";
import InputElement, {
	CheckBoxElement,
} from "../features/forms/components/InputElement";

const AccountGpsInformation = () => {
	// Vehicle store
	const {
		hasGPS,
		gps_ID,
		gpsCompany,
		gpsWebsite,
		gps_ID2,
		gpsCompany2,
		gpsWebsite2,
		hasGPS2,
		editAccount,
		account,
	} = useCurrentAccountStore(
		(state) => ({
			hasGPS: state.account.hasGPS,
			gps_ID: state.account.gps_ID,
			gpsCompany: state.account.gpsCompany,
			gpsWebsite: state.account.gpsWebsite,
			gps_ID2: state.account.gps_ID2,
			gpsCompany2: state.account.gpsCompany2,
			gpsWebsite2: state.account.gpsWebsite2,
			hasGPS2: state.account.hasGPS2,
			editAccount: state.editAccount,
			account: state.account,
		}),
		shallow
	);
	useEffect(() => {
		// eslint-disable-next-line
	}, [hasGPS, hasGPS2]);

	const methods = useFormContext();
	return (
		<>
			<div>
				<Form>
					<InputWrapper
						formTitle="GPS Information"
						centerTitle={false}
						inputComponents={[
							<>
								<h4 className="col-12 m-0">Primary GPS:</h4>
								<CheckBoxElement
									{...methods}
									checked={hasGPS}
									onChange={() =>
										editAccount({
											...account,
											hasGPS: !hasGPS,
										})
									}
									name="hasGps"
									label="Installed and Active"
									labelClass="pl-4 required"
								/>
								<InputElement
									{...methods}
									readOnly={!hasGPS}
									value={gps_ID}
									onChange={(e) =>
										editAccount({
											...account,
											gps_ID: e.target.value,
										})
									}
									name="gps_ID"
									label="GPS ID"
									type="text"
								/>
								<InputElement
									{...methods}
									readOnly={!hasGPS}
									value={gpsCompany}
									onChange={(e) => {
										editAccount({
											...account,
											gpsCompany: e.target.value,
										});
									}}
									//editGpsCompany(e.target.value)}
									name="gpsCompany"
									label="Company"
									type="text"
								/>
								<InputElement
									{...methods}
									readOnly={!hasGPS}
									value={gpsWebsite}
									onChange={(e) => {
										editAccount({
											...account,
											gpsWebsite: e.target.value,
										});
									}}
									name="gpsWebSite"
									label="WebSite"
									type="text"
								/>
								<h4 className="col-12 m-0">Secondary GPS:</h4>
								<CheckBoxElement
									{...methods}
									checked={hasGPS2}
									onChange={() =>
										editAccount({
											...account,
											hasGPS2: !hasGPS2,
										})
									}
									name="hasGps2"
									label="Installed and Active"
									labelClass="pl-4 required"
								/>
								<InputElement
									{...methods}
									readOnly={!hasGPS2}
									value={gps_ID2}
									onChange={(e) =>
										editAccount({
											...account,
											gps_ID2: e.target.value,
										})
									}
									name="gps_ID2"
									label="GPS ID"
									type="text"
								/>
								<InputElement
									{...methods}
									readOnly={!hasGPS2}
									value={gpsCompany2}
									onChange={(e) =>
										editAccount({
											...account,
											gpsCompany2: e.target.value,
										})
									}
									name="gpsCompany2"
									label="Company"
									type="text"
								/>
								<InputElement
									{...methods}
									readOnly={!hasGPS2}
									value={gpsWebsite2}
									onChange={(e) =>
										editAccount({
											...account,
											gpsWebsite2: e.target.value,
										})
									}
									name="gpsWebSite2"
									label="WebSite"
									type="text"
								/>
							</>,
						]}
					/>
				</Form>
			</div>
		</>
	);
};

export default AccountGpsInformation;

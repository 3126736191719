import React, { useState, useRef, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import CreatableSelect from "react-select/creatable";
import {
	Button,
	Col,
	Form,
	FormGroup,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalHeader,
	Spinner,
} from "reactstrap";
import shallow from "zustand/shallow";
import {
	DC_AI_API_BASE_URL,
	StateCodeByStateName,
	smogCheck,
	titleBrands,
	vehicleStatus,
} from "constants/Constants";
import useCurrentVehicleStore from "stores/InventoryStore";
import formNavigationUtils from "utils/formNavigationUtils";
import InputElement, {
	CurrencyInputElement,
	SelectorElement,
} from "../../../../forms/components/InputElement";
import InputWrapper from "../../../../forms/components/InputWrapper";
import Swal from "sweetalert2";

const VehicleDetails = () => {
	const [loading, setLoading] = useState(false);
	const [modalVisible, setModalVisible] = useState(false);
	const [descriptionLength, setDescriptionLength] = useState("short");
	const descriptionRef = useRef(null);

	// Vehicle store
	const {
		vin,
		year,
		make,
		model,
		odometerIn,
		trim,
		description,
		longDescription,
		weightFee,
		license,
		expireDate,
		status,
		location,
		reportOfSale,
		titleNo,
		titleDateOfIssue,
		newTitleReceivedDate,
		newTitleNumber,
		titleState,
		newTitleState,
		titleInDate,
		smogCheckDate,
		isSmogCheck,
		titleBrand,
		odometerOut,
		permitNumber,
		permitExpires,
		permitIssuedOn,
		editOdometerOut,
		editTitleBrand,
		editReportOfSale,
		editLocation,
		editDescription,
		editLongDescription,
		editWeightFee,
		editLicense,
		editExpireDate,
		editTitleNo,
		editTitleDateOfIssue,
		editNewTitleReceivedDate,
		editNewTitleNumber,
		editTitleInDate,
		editTitleState,
		editNewTitleState,
		editIsSmogCheck,
		editSmogCheckDate,
		editPermitNumber,
		editPermitExpires,
		editPermitIssuedOn,
	} = useCurrentVehicleStore((state) => state, shallow);
	const vehicleLocationsList = useCurrentVehicleStore(
		(state) => state.locationList
	);

	const methods = useFormContext();

	useEffect(() => {
		if (descriptionRef.current) {
			descriptionRef.current.style.height = "auto";
			descriptionRef.current.style.height = `${descriptionRef.current.scrollHeight}px`;
		}
	}, [longDescription]);

	const generateDescription = async () => {
		setLoading(true);
		setModalVisible(true);

		const params = {
			year: year,
			make: make,
			model: model,
			trim: trim,
			vin: vin,
			odometer: odometerIn,
			length: descriptionLength,
		};

		try {
			console.log("Sending request with params:", params); // Log the request params

			const response = await fetch(
				`${DC_AI_API_BASE_URL}/generate-description`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						"X-API-Key": "aardvark5000", // Add the API key here
					},
					body: JSON.stringify(params),
				}
			);

			console.log("Response status:", response.status); // Log response status
			console.log("Response headers:", response.headers); // Log response headers

			if (response.ok) {
				const data = await response.json(); // Ensure JSON parsing happens here
				console.log("Response data:", data); // Log the response data

				if (data && data.response) {
					const aiDescription = data.response;
					editLongDescription(aiDescription);
					console.log("AI description:", aiDescription); // Log the AI-generated description
				} else {
					console.error("No 'response' field in the response data:", data); // Log in case 'response' field is missing
					throw new Error("No 'response' field found in response");
				}
			} else {
				const errorText = await response.text();
				console.error("Failed to generate description:", errorText);
				throw new Error(errorText || "Failed to generate description");
			}
		} catch (error) {
			console.error("Error generating description from backend:", error);
			Swal.fire("Please Check Required Fields", error.message, "error");
		} finally {
			setLoading(false);
			setModalVisible(false);
		}
	};

	return (
		<Form onKeyDown={formNavigationUtils}>
			<InputWrapper
				formTitle="Vehicle Details"
				inputComponents={[
					<Controller
						name="locationList"
						defaultValue={location === null || location === "" ? "0" : location}
						render={({ field }) => (
							<FormGroup className="col">
								<div>
									<Label>Location</Label>
									<CreatableSelect
										isDisabled={
											status === vehicleStatus.SOLD ||
											status === vehicleStatus.DELETED
										}
										value={{ value: location, label: location }}
										options={vehicleLocationsList.map((value) => ({
											value: value,
											label: value,
										}))}
										onChange={(e) => {
											console.log(e);
											editLocation(e.value);
										}}
										{...field}
									/>
								</div>
							</FormGroup>
						)}
					/>,
					<InputElement
						readOnly={
							status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
						}
						{...methods}
						value={license}
						name="license"
						label="License Plate"
						type="text"
						onChange={(e) => {
							editLicense(e.target.value);
						}}
					/>,

					<InputElement
						readOnly={
							status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
						}
						{...methods}
						value={expireDate}
						name="expireDate"
						label="License Exp. Date"
						type="date"
						onChange={(e) => {
							editExpireDate(e.target.value);
						}}
					/>,
					<InputElement
						readOnly={
							status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
						}
						{...methods}
						value={titleInDate}
						name="titleInDate"
						label="Date Title In"
						type="date"
						onChange={(e) => {
							editTitleInDate(e.target.value);
						}}
					/>,
					<InputElement
						readOnly={
							status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
						}
						{...methods}
						value={titleNo}
						name="titleNo"
						label="Title No"
						type="text"
						onChange={(e) => {
							editTitleNo(e.target.value);
						}}
					/>,

					<SelectorElement
						readOnly={
							status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
						}
						{...methods}
						value={titleState}
						name="titleStateIssue"
						label="Title State Issue"
						type="text"
						onChange={(e) => {
							editTitleState(e.target.value);
						}}
						options={StateCodeByStateName}
					/>,
					<InputElement
						readOnly={
							status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
						}
						{...methods}
						value={newTitleReceivedDate}
						name="newTitleReceivedDate"
						label="New Title Date In"
						type="date"
						onChange={(e) => {
							editNewTitleReceivedDate(e.target.value);
						}}
					/>,

					<InputElement
						readOnly={
							status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
						}
						{...methods}
						value={newTitleNumber}
						name="newTitleNumber"
						label="New Title No"
						type="text"
						onChange={(e) => {
							editNewTitleNumber(e.target.value);
						}}
					/>,
					<SelectorElement
						readOnly={
							status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
						}
						{...methods}
						value={newTitleState}
						name="newTitleState"
						label="New Title State Issue"
						type="text"
						onChange={(e) => {
							editNewTitleState(e.target.value);
						}}
						options={StateCodeByStateName}
					/>,
					<InputElement
						readOnly={
							status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
						}
						{...methods}
						value={titleDateOfIssue}
						name="titleDateOfIssue"
						label="Title Date of Issue"
						type="date"
						onChange={(e) => {
							editTitleDateOfIssue(e.target.value);
						}}
					/>,
					<SelectorElement
						readOnly={
							status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
						}
						{...methods}
						name="titleBrand"
						label="Title Brand"
						value={titleBrand}
						onChange={(e) => editTitleBrand(e.target.value)}
						options={titleBrands}
					/>,

					<CurrencyInputElement
						readOnly={
							status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
						}
						value={weightFee}
						name="weightFee"
						label="Weight Fee"
						type="text"
						onChange={(e) => {
							editWeightFee(e);
						}}
					/>,
					<InputElement
						readOnly={
							status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
						}
						{...methods}
						value={smogCheckDate}
						name="smogCheckDate"
						label="Smog Check Date"
						type="date"
						onChange={(e) => {
							editSmogCheckDate(e.target.value);
						}}
					/>,

					<SelectorElement
						readOnly={
							status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
						}
						{...methods}
						name="isSmogCheck"
						label="Smog Check"
						value={isSmogCheck}
						onChange={(e) => editIsSmogCheck(e.target.value)}
						options={smogCheck}
					/>,
					<InputElement
						readOnly={
							status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
						}
						{...methods}
						value={reportOfSale}
						name="reportOfSale"
						label="Report Of Sale"
						type="text"
						onChange={(e) => {
							editReportOfSale(e.target.value);
						}}
					/>,
					<InputElement
						readOnly={
							status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
						}
						{...methods}
						value={odometerOut}
						name="odometerOut"
						label="Milage Out"
						type="number"
						onChange={(e) => {
							editOdometerOut(e.target.value);
						}}
					/>,
					<InputElement
						readOnly={
							status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
						}
						{...methods}
						value={permitNumber}
						name="permitNumber"
						label="Permit Number"
						type="text"
						onChange={(e) => editPermitNumber(e.target.value)}
					/>,
					<InputElement
						readOnly={
							status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
						}
						{...methods}
						value={permitExpires}
						name="permitExpires"
						label="Permit Expires On"
						type="date"
						onChange={(e) => {
							editPermitExpires(e.target.value);
						}}
					/>,
					<InputElement
						readOnly={
							status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
						}
						{...methods}
						value={permitIssuedOn}
						name="permitIssuedOn"
						label="Permit Issued On"
						type="date"
						onChange={(e) => {
							editPermitIssuedOn(e.target.value);
						}}
					/>,
					<FormGroup className="col-sm-3"></FormGroup>,
					<InputElement
						readOnly={
							status === vehicleStatus.SOLD || status === vehicleStatus.DELETED
						}
						{...methods}
						wholeRow={true}
						value={description}
						name="description"
						label="Comment"
						type="textarea"
						onChange={(e) => editDescription(e.target.value)}
					/>,
					<Col className="p-0" xs={12}>
						<FormGroup>
							<InputElement
								readOnly={
									status === vehicleStatus.SOLD ||
									status === vehicleStatus.DELETED
								}
								{...methods}
								wholeRow={true}
								value={longDescription}
								name="longDescription"
								label="WebSite Description"
								type="textarea"
								inputClass="invDescription"
								onChange={(e) => editLongDescription(e.target.value)}
								ref={descriptionRef}
							/>
							<Col>
								<FormGroup check inline>
									<Label className="p-0" check>
										<Input
											type="radio"
											name="descriptionLength"
											value="short"
											checked={descriptionLength === "short"}
											onChange={() => setDescriptionLength("short")}
											disabled={loading}
										/>{" "}
										Short
									</Label>
								</FormGroup>
								<FormGroup check inline>
									<Label className="p-0" check>
										<Input
											type="radio"
											name="descriptionLength"
											value="long"
											checked={descriptionLength === "long"}
											onChange={() => setDescriptionLength("long")}
											disabled={loading}
										/>{" "}
										Long
									</Label>
								</FormGroup>
							</Col>
							<Col>
								<Button
									color="primary"
									className="mb-2"
									onClick={generateDescription}
									disabled={loading}
								>
									{/* eslint-disable-next-line */}
									🤖 Generate Using DealerClick AI
								</Button>
								<p>
									** The more information you provide, the better the result
									will be.
								</p>
							</Col>
						</FormGroup>
					</Col>,
				]}
			/>
			<Modal isOpen={modalVisible}>
				<ModalHeader>Generating Description</ModalHeader>
				<ModalBody>
					<div className="text-center">
						<Spinner style={{ width: "3rem", height: "3rem" }} />
						<div>Processing...</div>
					</div>
				</ModalBody>
			</Modal>
		</Form>
	);
};

export default VehicleDetails;

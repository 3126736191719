import React, { useEffect } from "react";
import { Input } from "reactstrap";
import dayjs from "dayjs";
import { DealStatus } from "constants/Constants";
import { LoanPeriod } from "constants/Constants";

const FinanceDaysToPayInput = ({
	loanPeriod,
	daysToPay,
	editDaysToPay,
	firstDueDate,
	loanDate,
	type,
	dealId,
}) => {
	const re = /^\d{1,3}$/;

	const calculateDaysToPay = () => {
		const date1 = dayjs(loanDate);
		const date2 = dayjs(firstDueDate);

		console.log(date1);
		console.log(date2);
		const diff = date2.diff(date1, "day");
		console.log(diff);
		editDaysToPay(diff);
	};

	const daysInPeriod = LoanPeriod[loanPeriod].daysInPeriod;

	useEffect(() => {
		calculateDaysToPay();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		console.log(loanPeriod);
		console.log(dealId);

		if (dealId === null || dealId === 0) {
			editDaysToPay(LoanPeriod[loanPeriod].daysInPeriod);
		}
		// eslint-disable-next-line
	}, [editDaysToPay, loanPeriod]);

	return (
		<Input
			type="number"
			readOnly={
				(Number(loanPeriod) === 5 || type !== DealStatus.PENDING) &&
				type !== "BHPH"
			}
			onFocus={(e) => e.target.select()}
			value={daysToPay}
			onBlur={(e) => {
				let val = e.target.value;

				if (val === "") {
					editDaysToPay(daysInPeriod);
				} else {
					editDaysToPay(e.target.valueAsNumber);
				}
			}}
			onChange={(e) => {
				let changed = e.target.value;
				if (changed === "") {
					editDaysToPay(daysInPeriod);
				} else if (changed.match(re)) {
					editDaysToPay(e.target.valueAsNumber);
				}
			}}
		/>
	);
};

export default FinanceDaysToPayInput;

import { driveType } from "constants/Constants";
import React, { useState } from "react";
import {
	Card,
	CardBody,
	CardHeader,
	ListGroup,
	ListGroupItem,
	Collapse,
	Row,
	Col,
	Button,
} from "reactstrap";

/**
 * InventorySpecificEquipment Component
 * @param {Object} props - React props
 * @returns {JSX.Element}
 */
const InventorySpecificEquipment = ({ specificationEquip }) => {
	if (!specificationEquip) {
		return null;
	}
	const [open, setOpen] = useState(false);

	const handleCollapse = () => {
		setOpen(!open);
	};

	let specifications = [];

	try {
		const specObj = JSON.parse(specificationEquip);
		if (specObj && specObj.length) {
			specifications = specObj.map((item) => (
				<SpecificationItem key={item.Key} item={item} />
			));
		}
	} catch (e) {
		console.error("Error parsing specifications:", e.message);
	}

	// Determine the chunk size for 4 columns
	const chunkSize = Math.ceil(specifications.length / 4);

	const specificationChunks = [];
	for (let i = 0; i < specifications.length; i += chunkSize) {
		specificationChunks.push(specifications.slice(i, i + chunkSize));
	}

	// Ensure we always have 4 columns
	while (specificationChunks.length < 4) {
		specificationChunks.push([]);
	}

	return (
		<Card>
			<CardHeader
				style={{ justifyContent: "space-between" }}
				onClick={handleCollapse}
				className="cursor-pointer d-flex py-2"
			>
				<h3>Specifications</h3>{" "}
				<Button className="btn-sm btn-link">
					<i className="nc-icon nc-minimal-down" />
				</Button>
			</CardHeader>
			<Collapse isOpen={open}>
				<CardBody>
					<Row>
						{specificationChunks.map((chunk, index) => (
							<Col key={index} xs={12} sm={6} md={3}>
								{chunk.length > 0 ? chunk : <div>&nbsp;</div>}
							</Col>
						))}
					</Row>
				</CardBody>
			</Collapse>
		</Card>
	);
};

/**
 * SpecificationItem Component
 * @param {Object} props - React props
 * @param {Object} props.item - Specification item
 * @returns {JSX.Element}
 */
const SpecificationItem = ({ item }) => (
	<div>
		<h6>{item.Key}</h6>
		<ListGroup>
			{Object.keys(item.Value).map((key2, i) => (
				<ListGroupItem key={i}>
					{item.Value[key2].Name === "Drive Type"
						? driveType.find((e) => item.Value[key2].Value === e.value)?.label
						: `${item.Value[key2].Name} ${item.Value[key2].Value}`}
				</ListGroupItem>
			))}
		</ListGroup>
	</div>
);

export default InventorySpecificEquipment;

import React, { useState, useEffect } from "react";
import { useFromUser } from "stores/LocalStorageHelper";
import dayjs from "dayjs";
import { Button, Form } from "reactstrap";
import InputElement, {
	CurrencyInput,
	PercentInput,
} from "features/forms/components/InputElement";

import InputWrapper from "features/forms/components/InputWrapper";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import { setLocale } from "yup";
import { useForm, FormProvider } from "react-hook-form";
import shallow from "zustand/shallow";
import useCurrentAccountStore from "./AccountStore";
import { SelectorElement } from "../features/forms/components/InputElement";
import AccountHook from "./AccountHook";
import useAccountDefaultStore from "./AccountDefaultsStore";

const AccountLateForm = (props) => {
	const save = AccountHook();
	const { currentIndex, toggle } = props;
	const { acctDefaults } = useAccountDefaultStore((state) => state, shallow);

	const { lateFees, editLateFees, account } = useCurrentAccountStore(
		(state) => state,
		shallow
	);
	const today = dayjs().format("YYYY-MM-DD");
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");

	const [amount, setAmount] = useState(0);
	const [date, setdate] = useState(today);
	const [feeType, setFeeType] = useState(acctDefaults.lateType);
	const [latePercent, setLatePercent] = useState(
		acctDefaults.lateType === 1 ? 0 : acctDefaults.latePercent
	);
	const [lateAmount, setLateAmount] = useState(0);
	const [lateFixAmount, setLateFixAmount] = useState(0);
	const [formTitle, setFormTitle] = useState([]);

	useEffect(() => {
		if (currentIndex < lateFees.length) {
			setFormTitle("Edit LateFee ");
			setdate(lateFees[currentIndex]?.dateEntered || "");
			setAmount(lateFees[currentIndex]?.amount || 0);
			setFeeType(lateFees[currentIndex]?.feeType || 0);
			setLateAmount(lateFees[currentIndex]?.lateAmount || 0);
			setLateFixAmount(lateFees[currentIndex]?.lateFixAmount || 0);
			setLatePercent(lateFees[currentIndex]?.latePct || 0);
		} else {
			// 	clearFields();
			setFormTitle("Add LateFee");
			if (Number(acctDefaults.lateType) === 0) {
				setLateAmount(account.regularPayment);
				setLatePercent(acctDefaults.latePercent);
				setLateFixAmount(acctDefaults.lateFixAmount);
			} else if (Number(acctDefaults.lateType) === 1) {
				setLateFixAmount(acctDefaults.lateFixAmount);
				setLateAmount(0);
				setLatePercent(0);
			} else if (Number(acctDefaults.lateType) === 2) {
				setLateAmount(account.pastDueAmt);
				setLatePercent(acctDefaults.latePercent);
				setLateFixAmount(acctDefaults.lateFixAmount);
			}
		}
		// eslint-disable-next-line
	}, []);

	const addLate = () => {
		const getID = () => {
			if (lateFees[currentIndex] === undefined) {
				return null;
			} else if (lateFees[currentIndex].id === undefined) {
				return null;
			} else {
				return lateFees[currentIndex].id;
			}
		};
		const ID = getID();
		const newLate = {
			acctRecnum: account.id,
			amount,
			dateEntered: date,
			currentIndex,
			createdOn: today,
			dealerID,
			locationID,
			id: ID,
			feeType,
			latePct: latePercent,
			lateAmount,
			lateFixAmount,
		};

		const lateList = () => {
			let obj = lateFees;
			obj[currentIndex] = newLate;
			return obj;
		};
		const newlateFees = lateList();
		editLateFees(newlateFees);
		save();

		//		clearFields();
		toggle();
	};

	// Define custom error messages for different error types
	setLocale({
		mixed: {
			required: "Required",
		},
		string: {
			// eslint-disable-next-line
			max: "Must be ${max} characters or less",
			length: "Required format is YYYY/MM/DD",
		},
	});

	// Define rules for each input field
	const schema = yup.object().shape({});

	// Define form validation parameters
	const methods = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});
	const handleFeeTypeChange = (e) => {
		setFeeType(e.target.value);
		if (Number(e.target.value) === 0) {
			setLateAmount(account.regularPayment);
			setLatePercent(acctDefaults.latePercent);
			setLateFixAmount(acctDefaults.lateFixAmount);
		} else if (Number(e.target.value) === 1) {
			setLateFixAmount(acctDefaults.lateFixAmount);
			setLatePercent(0);
			setLateAmount(0);
		} else if (Number(e.target.value) === 2) {
			setLateAmount(account.pastDueAmt);
			setLatePercent(acctDefaults.latePercent);
			setLateFixAmount(acctDefaults.lateFixAmount);
		}
	};
	useEffect(() => {
		setAmount(latePercent * lateAmount + lateFixAmount);
	}, [feeType, lateAmount, latePercent, lateFixAmount]);
	return (
		<>
			<FormProvider className="" {...methods}>
				<Form>
					<InputWrapper
						formTitle={formTitle}
						inputComponents={[
							<InputElement
								{...methods}
								readOnly={!account.status === 0}
								value={date}
								onChange={(e) => {
									setdate(e.target.value);
								}}
								name="date"
								label="Date"
								type="date"
							/>,
							<SelectorElement
								{...methods}
								readOnly={account.status !== 0}
								name="feeType"
								label="Charge by"
								colSize="col-sm-4"
								value={feeType}
								onChange={handleFeeTypeChange}
								options={[
									{ value: 0, label: "Pct of Pmt" },
									{ value: 1, label: "Flat Fee" },
									{ value: 2, label: "Pct of Past Due" },
								]}
							/>,
							<CurrencyInput
								disableValidation
								readOnly={true}
								value={lateAmount}
								name="lateAmount"
								label="Base on Amount"
								type="number"
							/>,
							<PercentInput
								value={latePercent}
								onChange={setLatePercent}
								name="latePercent"
								label="Late Percent"
								disableValidation
							/>,
							<CurrencyInput
								value={lateFixAmount}
								onChange={setLateFixAmount}
								name="lateFixAmount"
								label="Fixed Amount"
								type="Currency"
								colSize="col-sm-3"
								disableValidation
							/>,
							<CurrencyInput
								disableValidation
								readOnly={!account.status === 0}
								value={amount}
								onChange={setAmount}
								name="amount"
								label="Amount"
								type="number"
							/>,
						]}
						buttons={
							<div style={{ display: "flex", justifyContent: "center" }}>
								<Button
									active={account.status === 0}
									color="primary"
									onClick={methods.handleSubmit(addLate)}
								>
									Save
								</Button>
								{/* <Button
									active={account.status === 0}
									color="danger "
									onClick={methods.handleSubmit(addLate)}
								>
									Void
								</Button> */}
							</div>
						}
					/>
				</Form>
			</FormProvider>
		</>
	);
};

export default AccountLateForm;

import React, { useState, useEffect } from "react";
import { useDigitzsMerchantId, useFromUser } from "stores/LocalStorageHelper";
import dayjs from "dayjs";
import { Button, Col, Form, Input, Row } from "reactstrap";
import InputElement, {
	CurrencyInput,
} from "../features/forms/components/InputElement";
import InputWrapper from "features/forms/components/InputWrapper";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import { setLocale } from "yup";
import { useForm, FormProvider } from "react-hook-form";
import shallow from "zustand/shallow";
import useCurrentAccountStore from "./AccountStore";
import { SelectorElement } from "../features/forms/components/InputElement";
import {
	PaymentMode,
	PaymentStatus,
	PaymentType,
	PaymentTypeToModeMapping,
	PmtMode,
	PmtType,
	paymentMethod,
} from "../constants/Constants";
import AccountHook from "./AccountHook";
import ReceiptButton from "./ReceiptButton";
import ListItemModal from "../features/settings/subFeatures/settingsTabs/signUp/ListItemModal";
import ChildrenModal from "../components/modals/ChildrenModal";
import DigitzsPayment from "../services/digitzsService/DigitzsPayment";
import DigitzsImg from "assets/img/digitzs-final-logo.png";
import Digitzs from "../features/settings/subFeatures/settingsTabs/signUp/digitzs/Digitzs";
import { deletePayment } from "./AccountAPI";
import useAccountDefaultStore from "./AccountDefaultsStore";
import AcctEditPayment from "./AcctEditPayment";

const AccountPaymentForm = (props) => {
	const { acctDefaults } = useAccountDefaultStore((state) => state, shallow);
	// const tax = (acct.getIsTaxDeferred()
	// 		? payment.getAmtPaid().multiply(BigDecimal.valueOf(acct.getSalesTaxRate())).setScale(2,
	// 				RoundingMode.HALF_UP)
	// 		: BigDecimal.ZERO);

	const save = AccountHook();
	const { currentIndex, toggle } = props;
	const { payments, editPayments, account } = useCurrentAccountStore(
		(state) => state,
		shallow
	);
	// console.log(account);
	// console.log(props);
	const today = dayjs().format("YYYY-MM-DD");
	// console.log(today);
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	const userID = useFromUser("ID");

	const [payment, setPayment] = useState(payments[currentIndex]);
	const [datePaid, setdatePaid] = useState(today);
	const [amtPaid, setAmtPaid] = useState(0);
	const [convenienceFee, setConvenienceFee] = useState(0);

	const [receiptNo, setreceiptNo] = useState("");
	const [method, setMethod] = useState("cash");

	const [checkNo, setCheckNo] = useState("");
	const [pmtType, setPmtType] = useState("Loan");
	const [pmtMode, setPmtMode] = useState(payments[currentIndex]?.pmtMode || 0);
	const [status, setStatus] = useState("completed");
	const [statusReason, setStatusReason] = useState("");
	const [note, setNote] = useState("");
	const [description, setDescription] = useState("");

	const [newPmt, setNewPmt] = useState(true);

	const [filteredPaymentMode, setFilteredPaymentMode] = useState([]);
	const [formTitle, setFormTitle] = useState([]);

	const [loanPaid, setLoanPaid] = useState(0);
	const [lateFeePaid, setLateFeePaid] = useState(0);
	const [principalPaid, setPrincipalPaid] = useState(0);
	const [interestPaid, setInterestPaid] = useState(0);
	//	const [debitPaid, setDebitPaid] = useState(0);
	const [taxPaid, setTaxPaid] = useState(0);
	const [adjFeePaid, setAdjFeePaid] = useState(0);
	const [pickupPaid, setPickupPaid] = useState(0);

	//const [userRecNum ,setUserRecNum] = useState();

	//	const dailyIntRate = account.apr / 365 / 100;
	const dailyIntRate = account.apr / acctDefaults.daysInYear / 100;

	const handleMethod = (e) => {
		setMethod(e);
		// if (
		// 	e === "check" ||
		// 	e === "cashierCheck" ||
		// 	e === "echeck" ||
		// 	e === "other"
		// )
		// 	sethideCheck(false);
		// else sethideCheck(true);
	};

	const merchantId = useDigitzsMerchantId();
	const [digitzsModal, setDigitzsModal] = useState(false);
	const toggleDigitzsModal = () => setDigitzsModal(!digitzsModal);
	const toggleDigitzsModalApi = () => {
		if (merchantId !== null) {
			failAlert(
				"Digitzs account already exists for this dealership with merchant ID: " +
					res.data.content.merchantId
			);
			return;
		}
		setDigitzsModal(!digitzsModal);
	};

	useEffect(() => {
		// console.log("Current index is: " + currentIndex);
		// console.log("payments length is: " + payments.length);
		// console.log(payments[currentIndex]);
		// console.log(payment);
		if (currentIndex < payments.length) {
			setNewPmt(false);
			setFormTitle("Edit Payment ");

			setdatePaid(payment?.datePaid || "");
			setAmtPaid(payment?.amtPaid || "");
			setConvenienceFee(payment?.convenienceFee || "");

			setreceiptNo(payment?.receiptNo || "");
			setCheckNo(payment?.checkNo);
			setMethod(payment?.method);
			setPmtType(payment?.pmtType);
			setPmtMode(payment?.pmtMode);
			setNote(payment?.note);
			setDescription(payment?.description);
			setStatus(payment?.status);
			setStatusReason(payment?.statusReason);

			setLateFeePaid(payment?.lateFeepaid || 0.0);
			setPrincipalPaid(payment?.principalPaid || 0.0);
			setInterestPaid(payment?.interestPaid || 0.0);
			setLoanPaid(payment?.principalPaid + payment?.interestPaid);
			//			setDebitPaid(payment?.debitPaid || 0.0);
			setTaxPaid(payment?.taxPaid || 0.0);
			setAdjFeePaid(payment?.adjFeePaid || 0.0);
			setPickupPaid(payment?.pickupPaid || 0.0);
		} else {
			const newPayment = {
				accountRecNum: account.id,
				amtPaid,
				lateFeepaid: lateFeePaid,
				adjFeePaid,
				convenienceFee,
				datePaid,
				receiptNo,
				method,
				checkNo,
				pmtType,
				pmtMode,
				status: status,
				statusReason,
				note,
				description,
				currentIndex,
				dealerID,
				locationID,
				id: null,
				userRecNum: userID,
				createOn: new Date(),
				appVersion: "2.0",
			};
			setPayment(newPayment);
			// 	clearFields();
			setNewPmt(true);
			setFormTitle("Add Payment");
		}
		//return clearFields;
		// eslint-disable-next-line
	}, []);

	const addPayment = () => {
		const getID = () => {
			if (payments[currentIndex] === undefined) {
				return null;
			} else if (payments[currentIndex].id === undefined) {
				return null;
			} else {
				return payments[currentIndex].id;
			}
		};
		const ID = getID();
		const newPayment = {
			...payment,
			acctRecnum: account.acctRecnum,
			amtPaid: parseFloat(amtPaid),
			lateFeepaid: lateFeePaid,
			adjFeePaid,
			convenienceFee: parseFloat(convenienceFee),
			datePaid,
			receiptNo,
			method,
			checkNo,
			pmtType,
			pmtMode,
			status: status,
			statusReason,
			note,
			description,
			currentIndex,
			dealerID,
			locationID,
			id: ID,
			userRecNum: userID,
		};
		// console.log(newPayment);
		// console.log("Current index is: " + currentIndex);

		const paymentList = () => {
			let obj = payments;
			obj[currentIndex] = newPayment;
			return obj;
		};
		const newPayments = paymentList();
		editPayments(newPayments);
		console.log(newPayments);
		save();

		//saveAccount(getAcct());
		//		clearFields();
		toggle();
	};
	const removePayment = (ID, index) => {
		console.log("Removing Payment with ID: " + ID);
		if (ID) {
			deletePayment(ID).then(
				(response) => {
					console.log(response);
					let obj = payments.filter((item) => item.id !== ID);
					editPayments(obj);
					setCurrentIndex(obj.length);
					deleteSuccessAlert();
				},
				(err) => {
					console.error(err);
					if (!err.isGeneralError) {
						showApiError(err, deleteFailAlert);
					}
				}
			);
		}
	};

	// Define custom error messages for different error types
	setLocale({
		mixed: {
			required: "Required",
		},
		string: {
			// eslint-disable-next-line
			max: "Must be ${max} characters or less",
			length: "Required format is YYYY/MM/DD",
		},
	});

	// Define rules for each input field
	const schema = yup.object().shape({});

	// Define form validation parameters
	const methods = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});
	useEffect(() => {
		if (pmtType && PaymentTypeToModeMapping[pmtType]) {
			const modeValues = PaymentTypeToModeMapping[pmtType];
			const filteredModes = PaymentMode.filter((mode) =>
				modeValues.includes(mode.value)
			);
			setFilteredPaymentMode(filteredModes);
		} else {
			setFilteredPaymentMode(PaymentMode);
		}
	}, [pmtType]);

	const calcBreakdown = () => {
		console.log("calcBreakdown");
		if (currentIndex < payments.length) return;
		let amountPaid = amtPaid;
		if (pmtType === PmtType.LOAN) {
			switch (parseInt(pmtMode)) {
				case PmtMode.LATE_FEE: {
					setLateFeePaid(amountPaid);
					break;
				}
				case PmtMode.PRINCIPAL_ONLY: {
					setPrincipalPaid(amountPaid);
					break;
				}
				case PmtMode.INTEREST_ONLY: {
					setInterestPaid(amountPaid);
					break;
				}
				case PmtMode.ADJUSTMENT: {
					setAdjFeePaid(amountPaid);
					break;
				}
				case PmtMode.REGULAR: {
					let lateFeeDue = account?.lateFeeDue || 0;
					if (amountPaid <= lateFeeDue) {
						setLateFeePaid(amountPaid);
						amountPaid = 0;
						break;
					} else {
						setLateFeePaid(lateFeeDue);
						amountPaid = amountPaid - lateFeeDue;
					}
					let totalDebitCredit = account.totalDebitCredit || 0;
					if (amountPaid < totalDebitCredit) {
						setAdjFeePaid(amountPaid);
						amountPaid = 0;
						break;
					} else {
						setAdjFeePaid(totalDebitCredit);
						amountPaid = amountPaid - totalDebitCredit;
					}
					calcLoan(amountPaid);
					break;
				}
				case PmtMode.CUSTOM: {
					console.log("custom");
					calcLoan(loanPaid);
					//	setLoanPaid(amountPaid);
					break;
				}
				default:
					console.log("default");
					break;
			}
		}
		if (pmtType === PmtType.PICKUP) {
			setPickupPaid(amountPaid);
		}
	};

	const calcLoan = (amountPaid) => {
		console.log("amountPaid: " + amountPaid);
		let monthlyTax = account.monthlyTax || 0;
		if (amountPaid < monthlyTax) {
			setTaxPaid(amountPaid);
			amountPaid = 0;
		} else {
			setTaxPaid(monthlyTax);
			amountPaid = amountPaid - monthlyTax;
		}
		console.log("lastScheduledPmtDate: " + account.lastScheduledPmtDate);
		console.log("gracePeriod: " + acctDefaults.gracePeriod);
		let graceDate = dayjs(account.lastScheduledPmtDate)
			.add(acctDefaults.gracePeriod, "days")
			.format("YYYY-MM-DD");
		console.log("graceDate: " + graceDate);
		console.log("Date Paid: " + datePaid);
		console.log("Actual Last Date Paid: " + account.actualLastDatePaid);

		let days = 0;
		if (dayjs(datePaid).isAfter(graceDate)) {
			days = dayjs(datePaid).diff(account.actualLastDatePaid, "day");
		} else {
			days = dayjs(account.lastScheduledPmtDate).diff(
				account.actualLastDatePaid,
				"day"
			);
		}
		console.log("graceDate: " + graceDate);
		console.log("days: " + days);
		if (days < 0) days = 0;
		// let days = dayjs(datePaid).diff(account.actualLastDatePaid, "day");
		// console.log("days: " + days);
		// if (days < 0) days = 0;
		let interest =
			days * dailyIntRate * (account.amtFinanced - account.principle);
		if (interest - amountPaid > 0) interest = amountPaid;
		let principle = amountPaid - interest;
		principle = principle < 0 ? 0 : principle;
		// console.log(amountPaid);
		setInterestPaid(interest);
		setPrincipalPaid(principle);
	};

	useEffect(() => {
		if (amtPaid !== 0) {
			calcBreakdown();
		}
	}, [amtPaid]);

	// Avoid running setAmtPaid unless it's necessary
	useEffect(() => {
		if (pmtMode === "99" || pmtMode === 99) {
			// Ensure amtPaid only gets updated when it has changed
			const newAmtPaid = loanPaid + lateFeePaid + adjFeePaid;
			if (newAmtPaid !== amtPaid) {
				setAmtPaid(newAmtPaid); // Update amtPaid only if it's different
			}
		}
	}, [loanPaid, lateFeePaid, adjFeePaid, pmtMode, amtPaid]);

	const [isPopupOpen, setIsPopupOpen] = useState(false);
	const popupToggle = () => setIsPopupOpen(!isPopupOpen);
	const handleKeyPress = (event) => {
		if (event.ctrlKey && event.altKey && event.key === "3") {
			setIsPopupOpen(true); // Open the popup when Ctrl + Alt + 3 is pressed
		}
	};
	useEffect(() => {
		window.addEventListener("keydown", handleKeyPress);
		return () => {
			window.removeEventListener("keydown", handleKeyPress);
		};
	}, []);
	return (
		<>
			{currentIndex === payments.length && (
				<div className="to-be-paid-card">
					<FormProvider className="" {...methods}>
						<Form>
							<InputWrapper
								formTitle="To Be Paid"
								inputComponents={[
									<CurrencyInput
										disableValidation
										readOnly={true}
										value={(
											account.principleDue + account.interestDue || 0.0
										).toFixed(2)}
										onChange={(e) => {
											console.log(e);
										}}
										name="loanDue"
										label="Loan Due"
										type="number"
									/>,
									<CurrencyInput
										disableValidation
										readOnly={true}
										value={account.lateFeeDue || 0.0}
										onChange={(e) => {
											console.log(e);
										}}
										name="lateFeeDue"
										label="Late Fee Due"
										type="number"
									/>,
									<CurrencyInput
										disableValidation
										readOnly={true}
										value={account.totalDebitCredit || 0.0}
										onChange={(e) => {
											console.log(e);
										}}
										name="adjustmentDue"
										label="Adjustment Due"
										type="number"
									/>,
									<CurrencyInput
										disableValidation
										readOnly={true}
										value={account.pickupPastDue || 0.0}
										onChange={(e) => {
											console.log(e);
										}}
										name="pickupPastDue"
										label="Pickup Past Due"
										type="number"
									/>,
									<CurrencyInput
										disableValidation
										readOnly={true}
										value={account.regularPayment}
										onChange={(e) => {
											console.log(e);
										}}
										name="regularPayment"
										label="Regular Payment"
										type="number"
									/>,
									<CurrencyInput
										disableValidation
										readOnly={true}
										value={account.payoffAmount || 0.0}
										onChange={(e) => {
											console.log(e);
										}}
										name="payoffAmount"
										label="Payoff Amount"
										type="number"
									/>,
								]}
							/>
						</Form>
					</FormProvider>
				</div>
			)}
			<FormProvider className="" {...methods}>
				<Form>
					<InputWrapper
						formTitle={formTitle}
						inputComponents={[
							<InputElement
								{...methods}
								readOnly={!newPmt}
								value={datePaid}
								onChange={(e) => {
									setdatePaid(e.target.value);
								}}
								name="datePaid"
								label="Date Paid"
								type="date"
							/>,
							<SelectorElement
								{...methods}
								readOnly={!newPmt}
								name="pmtType"
								label="Payment Type"
								value={pmtType}
								onChange={(e) => setPmtType(e.target.value)}
								options={PaymentType}
							/>,
							<SelectorElement
								{...methods}
								readOnly={!newPmt}
								name="pmtmode"
								label="Payment Mode"
								value={pmtMode}
								onChange={(e) => setPmtMode(e.target.value)}
								options={filteredPaymentMode}
							/>,
							<SelectorElement
								{...methods}
								readOnly={!newPmt}
								name="method"
								label="Methods"
								value={method}
								onChange={(e) => handleMethod(e.target.value)}
								options={paymentMethod}
							/>,

							(pmtMode === "99" || pmtMode === 99) && (
								<>
									<CurrencyInput
										disableValidation
										readOnly={!newPmt}
										value={loanPaid}
										onChange={(e) => {
											setLoanPaid(e);
										}}
										name="loanPaid"
										label="Loan Paid"
										type="number"
									/>
									,
									<CurrencyInput
										disableValidation
										readOnly={!newPmt}
										value={lateFeePaid}
										onChange={(e) => {
											console.log(e);
											if (e > account.lateFeeDue) {
												setLateFeePaid(account.lateFeeDue);
											} else {
												setLateFeePaid(e);
											}
										}}
										name="lateFeePaid"
										label="Late Fee Paid"
										type="number"
									/>
									,
									<CurrencyInput
										disableValidation
										readOnly={!newPmt}
										value={adjFeePaid}
										onChange={(e) => {
											if (e > account.totalDebitCredit) {
												setAdjFeePaid(account.totalDebitCredit);
											} else {
												setAdjFeePaid(e);
											}
										}}
										name="adjFeePaid"
										label="Adjustment Paid"
										type="number"
									/>
									,
								</>
							),

							<CurrencyInput
								disableValidation
								readOnly={!newPmt || pmtMode === "99" || pmtMode === 99}
								value={amtPaid}
								onChange={setAmtPaid}
								name="amountPaid"
								label="Amount Paid"
								type="number"
							/>,
							<CurrencyInput
								disableValidation
								readOnly={!newPmt}
								value={convenienceFee}
								onChange={setConvenienceFee}
								name="convenienceFee"
								label="Convenience Fee"
								type="number"
							/>,
							<CurrencyInput
								disableValidation
								readOnly={!newPmt}
								value={amtPaid + convenienceFee}
								//onChange={setAmtPaid}
								name="totalPaid"
								label="Total Paid"
								type="number"
							/>,
							<SelectorElement
								{...methods}
								readOnly={
									currentIndex !==
									payments.findLastIndex(
										(payment) => payment.status === "completed"
									)
								}
								disabled={
									currentIndex !==
									payments.findLastIndex(
										(payment) => payment.status === "completed"
									)
								}
								name="paymentStatus"
								label="Payment Status"
								value={status}
								onChange={(e) => setStatus(e.target.value)}
								options={PaymentStatus}
							/>,
							<InputElement
								{...methods}
								// readOnly={!newPmt}
								value={receiptNo}
								onChange={(e) => {
									setreceiptNo(e.target.value);
								}}
								name="receiptNo"
								label="Receipt #"
								type="text"
							/>,
							<InputElement
								{...methods}
								// readOnly={!newPmt}
								value={checkNo}
								onChange={(e) => {
									setCheckNo(e.target.value);
								}}
								name="checkNo"
								label="Reference No"
								type="text"
							/>,
							<InputElement
								{...methods}
								value={statusReason}
								onChange={(e) => {
									setStatusReason(e.target.value);
								}}
								name="statusReason"
								label="Status Reason"
								type="text"
							/>,
							<div>
								{merchantId &&
									newPmt &&
									(method === "creditCard" || method === "debitCard") && (
										<Button onClick={toggleDigitzsModal}>
											Process Credit Card
										</Button>
									)}
							</div>,
							<InputElement
								{...methods}
								value={description}
								onChange={(e) => {
									setDescription(e.target.value);
								}}
								colSize="col-sm-9"
								name="description"
								label="Description"
								type="text"
							/>,
							// <Input
							// 	className="pl-2"
							// 	placeholder="Add new note"
							// 	type="textarea"
							// 	value={note}
							// 	onChange={(e) => setNote(e.target.value)}
							// />,

							<InputElement
								{...methods}
								value={note}
								colSize="col-sm-12"
								onChange={(e) => {
									setNote(e.target.value);
								}}
								name="note"
								label="Note"
								type="textarea"
							/>,
						]}
					/>
					{/* {merchantId && (
						<>
							<ChildrenModal modal={digitzsModal} toggle={toggleDigitzsModal}>
								<DigitzsPayment pmtAmount={amtPaid} />
							</ChildrenModal>
							<Button onClick={toggleDigitzsModal}>Process Credit Card</Button>
						</>
					)} */}
				</Form>
			</FormProvider>
			<ChildrenModal size="lg" modal={digitzsModal} toggle={toggleDigitzsModal}>
				<DigitzsPayment pmtAmount={amtPaid + convenienceFee} />
			</ChildrenModal>

			<div className="payment-breakdown-card">
				<FormProvider className="" {...methods}>
					<Form>
						<InputWrapper
							formTitle="Payment Breakdown"
							inputComponents={[
								<CurrencyInput
									disableValidation
									readOnly={true}
									value={lateFeePaid}
									onChange={(e) => {
										console.log(e);
									}}
									name="lateFeePaid"
									label="Late Fee Paid"
									type="number"
								/>,
								<CurrencyInput
									disableValidation
									readOnly={true}
									onChange={(e) => {
										console.log(e);
									}}
									value={adjFeePaid}
									name="adjFeePaid"
									label="Adjustment Paid"
									type="number"
								/>,
								<CurrencyInput
									disableValidation
									readOnly={true}
									value={taxPaid}
									onChange={(e) => {
										console.log(e);
									}}
									name="taxPaid"
									label="Tax Paid"
									type="number"
								/>,
								<CurrencyInput
									disableValidation
									readOnly={true}
									value={principalPaid}
									onChange={(e) => {
										console.log(e);
									}}
									name="principlePaid"
									label="Principle Paid"
									type="number"
								/>,
								<CurrencyInput
									disableValidation
									readOnly={true}
									value={interestPaid}
									onChange={(e) => {
										console.log(e);
									}}
									name="interestPaid"
									label="Interest Paid"
									type="number"
								/>,
								<CurrencyInput
									disableValidation
									readOnly={true}
									onChange={(e) => {
										console.log(e);
									}}
									value={pickupPaid}
									name="pickupPaid"
									label="Pickup Paid"
									type="number"
								/>,
							]}
							buttons={
								<div style={{ display: "flex", justifyContent: "center" }}>
									<Button
										active={account.status === 0}
										color="primary"
										onClick={methods.handleSubmit(addPayment)}
									>
										Save
									</Button>
									<ReceiptButton
										paymentId={payment?.id}
										accountID={account?.ID}
									/>

									{currentIndex ===
										payments.findLastIndex(
											(payment) => payment.status === "completed"
										) &&
										payment?.status !== "void" && (
											<Button
												active={account.status === 0}
												color="danger "
												onClick={() => {
													setStatus("void");
													methods.handleSubmit(addPayment);
												}}
											>
												Void
											</Button>
										)}
									{payment?.status === "void" && (
										<Button
											color="danger "
											onClick={() => {
												methods.handleSubmit(removePayment);
											}}
										>
											Remove
										</Button>
									)}
								</div>
							}
						/>
					</Form>
				</FormProvider>
				{!merchantId && newPmt && (
					<Row className="mt-2 justify-content-center">
						<Col xs="12" sm="12" md="6" lg="6" xl="4" className="mb-3 d-grid ">
							<ListItemModal
								modalListTitle="Digitzs"
								modalButtonTitle="Sign Up"
								modalTitle="Digitzs Sign Up"
								modal={digitzsModal}
								toggle={toggleDigitzsModalApi}
								cardClassName="mb-2"
								imageLogo={DigitzsImg}
								disabled={false}
								integrationInfo="Digitzs is our preferred payment processing solution. Low rates, quick transfers, and fully integrated into your system."
							>
								<Digitzs toggle={toggleDigitzsModal} />
							</ListItemModal>
						</Col>
					</Row>
				)}

				<ChildrenModal
					size="lg"
					modal={isPopupOpen}
					toggle={popupToggle}
					modalTitle="Edit Payment"
				>
					<AcctEditPayment currentIndex={currentIndex} toggle={toggle} />
				</ChildrenModal>
			</div>
		</>
	);
};

export default AccountPaymentForm;

import useCurrentVehicleStore from "../stores/InventoryStore";
import { useFromUser } from "../stores/LocalStorageHelper";
import { convertFromReactSelect } from "../features/inventory/subFeatures/selector/helperFunction/SelectorFormat.js";
import shallow from "zustand/shallow";
import { version } from "../version";
// BuildInventoryOBJ
export const useBuildVehicle = () => {
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	// Vehicle Store Values
	const vehicleObj = useCurrentVehicleStore((state) => state, shallow);
	const vehicle = {
		...vehicleObj,
		optionalEquip: convertFromReactSelect(vehicleObj.optionalEquip),
		dealerID,
		locationID,
		askingPrice: parseFloat(vehicleObj.askingPrice),
		pack: parseFloat(vehicleObj.pack),
		cost: parseFloat(vehicleObj.cost),
		notes: vehicleObj.notes.length > 0 ? JSON.stringify(vehicleObj.notes) : "",
		onlinePrice: parseFloat(vehicleObj.onlinePrice),
		wholesalePrice: parseFloat(vehicleObj.wholesalePrice),
		dCVersion: "v" + version,
	};

	// const {
	// 	inventoryID,
	// 	stockNo,
	// 	year,
	// 	make,
	// 	model,
	// 	trim,
	// 	odometerIn,
	// 	color,
	// 	intColor,
	// 	condition,
	// 	transmission,
	// 	doors,
	// 	engine,
	// 	mPGHwy,
	// 	mPGCity,
	// 	weight,
	// 	tag,
	// 	description,
	// 	longDescription,
	// 	titleNo,
	// 	customEquip,
	// 	selectedEquip,
	// 	optionalEquip,
	// 	type,
	// 	vin,
	// 	standardEquip,
	// 	specificationEquip,
	// 	askingPrice,
	// 	dateIn,
	// 	pack,
	// 	cost,
	// 	downPmt,
	// 	msrp,
	// 	weightFee,
	// 	isDraft,
	// 	datePaid,
	// 	checkNo,
	// 	newUsed,
	// 	license,
	// 	expireDate,
	// 	location,
	// 	buyer,
	// 	notes,
	// 	vendorRecNum,
	// 	subModel,
	// 	status,
	// 	thumbnail,
	// 	odometerOut,
	// 	driveTrain,
	// 	fuelType,
	// 	reportOfSale,
	// 	onlinePrice,
	// 	wholesalePrice,
	// 	numCylinders,
	// 	actualMileageFlag,
	// 	titleInDate,
	// 	titleState,
	// 	newTitleState,
	// 	newTitleReceivedDate,
	// 	newTitleNumber,
	// 	titleDateOfIssue,
	// 	shippedDate,
	// 	auctionDate,
	// 	smogCheckDate,
	// 	soldDate,
	// 	createdByID,
	// 	modifiedByID,
	// 	isSmogCheck,
	// 	openedBy,
	// 	modifiedOn,
	// 	createdOn,
	// 	dateFloored,
	// 	flooringInt,
	// 	flooringComp,
	// 	flooringAmt,
	// 	flooringValue,
	// 	flooringAdminFee,
	// 	flooringCheckNo,
	// 	isFloored,
	// 	flooringStopDate,
	// 	isTradeIn,
	// 	titleBrand,
	// 	paymentMethod,
	// 	tradeLenderRecNum,
	// 	allowance,
	// 	acv,
	// 	payoff,
	// 	hasGPS,
	// 	gps_ID,
	// 	gpsCompany,
	// 	gpsWebsite,
	// 	gps_ID2,
	// 	gpsCompany2,
	// 	gpsWebsite2,
	// 	hasGPS2,
	// 	videoURL,
	// 	isAdvertised,
	// 	axelCount,
	// 	category,
	// 	subCategory,
	// 	isFetTaxable,
	// 	trailerIsNew,
	// 	hours,
	// 	engineManufacturer,
	// 	engineSerialNumber,
	// 	engineDisplacement,
	// 	transmissionManufacturer,
	// 	transmissionType,
	// 	numberOfSpeeds,
	// 	ratio,
	// 	drive,
	// 	suspension,
	// 	frontAxleWeight,
	// 	rearAxleWeight,
	// 	wheels,
	// 	wheelbase,
	// 	fifthWheelType,
	// 	brakeSystemType,
	// 	permitNumber,
	// 	permitExpires,
	// 	permitIssuedOn,
	// } = useCurrentVehicleStore((state) => state, shallow);

	// const vehicle = {
	// 	inventoryID,
	// 	stockNo,
	// 	year,
	// 	make,
	// 	model,
	// 	trim,
	// 	odometerIn,
	// 	color,
	// 	intColor,
	// 	condition,
	// 	transmission,
	// 	doors,
	// 	engine,
	// 	mPGHwy,
	// 	mPGCity,
	// 	weight,
	// 	tag,
	// 	description,
	// 	longDescription,
	// 	titleNo,
	// 	customEquip,
	// 	selectedEquip,
	// 	optionalEquip: convertFromReactSelect(optionalEquip),
	// 	type,
	// 	vin,
	// 	standardEquip,
	// 	specificationEquip,
	// 	dealerID,
	// 	locationID,
	// 	askingPrice: parseFloat(askingPrice),
	// 	dateIn,
	// 	pack: parseFloat(pack),
	// 	cost: parseFloat(cost),
	// 	downPmt,
	// 	msrp,
	// 	weightFee,
	// 	isDraft,
	// 	datePaid,
	// 	checkNo,
	// 	newUsed,
	// 	license,
	// 	expireDate,
	// 	location,
	// 	buyer,
	// 	notes: notes.length > 0 ? JSON.stringify(notes) : "",
	// 	vendorRecNum,
	// 	subModel,
	// 	status,
	// 	thumbnail,
	// 	odometerOut,
	// 	driveTrain,
	// 	fuelType,
	// 	reportOfSale,
	// 	onlinePrice: parseFloat(onlinePrice),
	// 	wholesalePrice: parseFloat(wholesalePrice),
	// 	numCylinders,
	// 	actualMileageFlag,
	// 	titleInDate,
	// 	titleState,
	// 	newTitleState,
	// 	newTitleReceivedDate,
	// 	newTitleNumber,
	// 	titleDateOfIssue,
	// 	shippedDate,
	// 	auctionDate,
	// 	smogCheckDate,
	// 	soldDate,
	// 	dCVersion: "v" + version,
	// 	createdByID,
	// 	modifiedByID,
	// 	isSmogCheck,
	// 	openedBy,
	// 	modifiedOn,
	// 	createdOn,
	// 	dateFloored,
	// 	flooringInt,
	// 	flooringComp,
	// 	flooringAmt,
	// 	flooringValue,
	// 	flooringAdminFee,
	// 	flooringCheckNo,
	// 	isFloored,
	// 	flooringStopDate,
	// 	isTradeIn,
	// 	titleBrand,
	// 	paymentMethod,
	// 	tradeLenderRecNum,
	// 	allowance,
	// 	acv,
	// 	payoff,
	// 	hasGPS,
	// 	gps_ID,
	// 	gpsCompany,
	// 	gpsWebsite,
	// 	gps_ID2,
	// 	gpsCompany2,
	// 	gpsWebsite2,
	// 	hasGPS2,
	// 	videoURL,
	// 	isAdvertised,
	// 	axelCount,
	// 	category,
	// 	subCategory,
	// 	isFetTaxable,
	// 	trailerIsNew,
	// 	hours,
	// 	engineManufacturer,
	// 	engineSerialNumber,
	// 	engineDisplacement,
	// 	transmissionManufacturer,
	// 	transmissionType,
	// 	numberOfSpeeds,
	// 	ratio,
	// 	drive,
	// 	suspension,
	// 	frontAxleWeight,
	// 	rearAxleWeight,
	// 	wheels,
	// 	wheelbase,
	// 	fifthWheelType,
	// 	brakeSystemType,
	// 	permitNumber,
	// 	permitExpires,
	// 	permitIssuedOn,
	// };
	const getVehicle = () => {
		console.log(vehicle);
		return vehicle;
	};
	return getVehicle;
};
export default useBuildVehicle;

import React, { useEffect, useState } from "react";
import {
	Table,
	Button,
	CardBody,
	Card,
	CardHeader,
	CardTitle,
	Row,
	Col,
	CardText,
} from "reactstrap";
import { CurrencyDisplayElement } from "features/deal/components/DisplayElement";
import dayjs from "dayjs";
import shallow from "zustand/shallow";
import useCurrentAccountStore from "./AccountStore";
import AccountAddDeferredForm from "./AccountAddDeferredForm";
import ChildrenModal from "../components/modals/ChildrenModal";
import NumberFormat from "react-number-format";

const AccountAddDeferred = () => {
	const acctObj = useCurrentAccountStore((state) => state, shallow);
	const pickupPmtsHead = ["#", "Days", "Date Due", "Amount", "Actions"];
	const [pickupPmts, setPickupPmts] = useState(acctObj.pickupPmts);

	const [currentIndex, setCurrentIndex] = useState(pickupPmts.length);
	const [open, setOpen] = useState(false);

	const toggleOpen = () => setOpen(!open);

	/**
	 * Deletes a pickup payment and updates the state.
	 *
	 * @param {string} ID - The ID of the payment to delete.
	 * @param {number} index - The index of the payment to delete.
	 */
	const removePickup = (ID, index) => {
		const updatedPickupPmts = pickupPmts.filter(
			(item) => item.currentIndex !== index
		);
		acctObj.editPickupPmts(updatedPickupPmts);
		calculate();
	};
	const calculate = () => {
		acctObj.pickupPmts.forEach((key, index) => {
			let totPickups = 0;
			if (index === currentIndex) {
				totPickups = key.amount;
			}
		});
		const pickupTotal = acctObj.pickupPmts.reduce(
			(acc, curr) => acc + curr.amount,
			0
		);
		acctObj.editAccount({ ...acctObj.account, pickupTotal: pickupTotal });
	};

	const tableHead = pickupPmtsHead.map((key, index) => (
		<th key={index}>{key}</th>
	));

	const rows = acctObj.pickupPmts.map((payment, index) => (
		<tr key={index}>
			<td scope="row">{index + 1}</td>
			<td>{dayjs(payment.dateDue).diff(acctObj.account.loanDate, "day")}</td>
			<td>{dayjs(payment.dateDue).format("MM/DD/YYYY")}</td>
			<td>
				<CurrencyDisplayElement val={payment.amount} />
			</td>
			<td className="text-center">
				<Button
					className="btn-sm"
					color="primary"
					onClick={() => {
						setCurrentIndex(index);
						toggleOpen();
					}}
				>
					Edit
				</Button>
				<Button
					className="btn-sm"
					color="danger"
					onClick={() => {
						setCurrentIndex(index);
						removePickup(payment.ID, payment.currentIndex);
					}}
				>
					X
				</Button>
			</td>
		</tr>
	));
	useEffect(() => {
		calculate();
		// eslint-disable-next-line
	}, [acctObj.pickupPmts.length]);

	return (
		<div>
			<Card className="my-3">
				<CardHeader className="text-center">
					<CardTitle tag="h3">Deferred Payments</CardTitle>
				</CardHeader>
				<CardBody className="py-0">
					<div style={{ overflowX: "auto" }}>
						<Table striped bordered hover>
							<thead>
								<tr>{tableHead}</tr>
							</thead>
							{rows.length === 0 ? (
								<tbody>
									<tr>
										<td>No Deferred Payments</td>
									</tr>
								</tbody>
							) : (
								rows
							)}
						</Table>
					</div>
					<ChildrenModal
						modal={open}
						toggle={toggleOpen}
						size="lg"
						children={
							<AccountAddDeferredForm
								acctObj={acctObj}
								currentIndex={currentIndex}
								setCurrentIndex={setCurrentIndex}
								toggle={toggleOpen}
							/>
						}
					/>

					<Button
						className="btn btn-primary btn-md"
						onClick={() => {
							setCurrentIndex(acctObj.pickupPmts.length);
							toggleOpen();
						}}
					>
						<i className="nc-icon nc-simple-add"></i> Add Deferred
					</Button>
					<Card className="card-plain">
						<Row className="justify-content-center">
							<Col sm="6">
								<CardText tag="h3" className="text-center">
									Total Deferred
								</CardText>
							</Col>
							<hr className="mb-2 w-75 mx-auto" />
							<Col sm="6">
								<CardText tag="h3" className="text-center">
									<NumberFormat
										value={acctObj.account.pickupTotal}
										thousandSeparator
										decimalScale={2}
										fixedDecimalScale
										prefix="$"
										isNumericString
										readOnly
										displayType="text"
									/>
								</CardText>
							</Col>
						</Row>
					</Card>
				</CardBody>
			</Card>
		</div>
	);
};
export default AccountAddDeferred;

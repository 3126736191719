import React, { useEffect, useState } from "react";
import { Button, Col, Table } from "reactstrap";
import NoteModalBody from "./NoteModalBody";
import NoteListData from "./NoteListData";
import NoteModalButton from "./NoteModalButton";

import { ADD } from "./NoteConstants";
import ChildrenModal from "../../components/modals/ChildrenModal";
/**
 * Render this component with the props in order to use the
 * functionality of all the note components. Data is saved on the
 * notes prop field passed in and can be saved to the database by
 * simply saving the respective store object to the database.
 *
 * Component makes use of React.Children and React Component Composition.
 * @see https://reactjs.org/docs/composition-vs-inheritance.html
 *
 * @param {Array} notes - Store Notes array containing objects with
 * information about each note.
 * @param {React.SetStateAction} editNotes - Setter function for the
 * Store notes field.
 * @param {boolean} disableAddAndSave - Disables the adding and saving
 * of notes
 * @param {Function} saveNotes - Calls save api to save the object with
 * the updated notes.
 */
const NoteButton = ({ notes, editNotes, disableAddAndSave, saveNotes }) => {
	// Local states
	const [lNote, setLNote] = useState("");
	const [addText, setAddText] = useState(ADD);
	const [index, setIndex] = useState(notes?.length);
	const [openModal, setOpenModal] = useState(false);
	const [openForm, setOpenForm] = useState(false);

	// Set local states to initial values
	const clearFields = () => {
		setLNote("");
		setIndex(notes.length);
	};

	useEffect(() => {
		setIndex(notes.length);
	}, [notes]);

	// Function to open the modal
	const toggle = () => setOpenModal(!openModal);

	const toggleForm = () => setOpenForm(!openForm);

	return (
		<>
			{openModal && (
				<NoteModal modal={openModal} toggle={toggle}>
					<ChildrenModal
						size="lg"
						modal={openForm}
						toggle={toggleForm}
						modalTitle="Notes"
						onClosed={clearFields}
						children={
							<>
								<NoteModalBody lNote={lNote} setLNote={setLNote} />
								<NoteModalButton
									index={index}
									lNote={lNote}
									notes={notes}
									buttonText={addText}
									editNotes={editNotes}
									setButtonText={setAddText}
									clearFields={clearFields}
									disableButtons={disableAddAndSave}
									saveNotes={saveNotes}
									toggle={toggleForm}
								/>
							</>
						}
					/>
					<Col className="mb-2">
						<Button onClick={toggleForm}>Add New</Button>
					</Col>
					<Col>
						<div style={{ overflowX: "auto" }}>
							{notes.length > 0 && (
								<Table striped hover>
									<thead>
										<tr className="align-top">
											<th>#</th>
											<th>Created By</th>
											<th>Created On</th>
											<th>Modified By</th>
											<th>Modified On</th>
											<th>Description</th>
											<th>Actions</th>
										</tr>
									</thead>
									<tbody>
										<NoteListData
											notes={notes}
											editNotes={editNotes}
											setLNote={setLNote}
											setIndex={setIndex}
											setButtonText={setAddText}
											clearFields={clearFields}
											disableButtons={disableAddAndSave}
											saveNotes={saveNotes}
											toggle={toggleForm}
										/>
									</tbody>
								</Table>
							)}
						</div>
					</Col>
				</NoteModal>
			)}
			<div className="btn btn-primary btn-md" onClick={toggle}>
				<i className="nc-icon nc-bullet-list-67"></i> Notes
			</div>
		</>
	);
};

export default NoteButton;

/**
 *	Renders the whole modal
 *
 * @param {boolean} modal - Local state for handling modal open/close
 * @callback toggle - Sets the modal state to !{@param modal}
 * @param {React.Children} - Children component to render in modal body
 * */
const NoteModal = ({ modal, toggle, children }) => {
	return (
		<ChildrenModal
			toggle={toggle}
			modal={modal}
			children={children}
			modalTitle="Notes"
		/>
	);
};

import React, { useState } from "react";
import {
	Col,
	Row,
	Label,
	CardHeader,
	CardTitle,
	CardBody,
	Card,
} from "reactstrap";
import shallow from "zustand/shallow";
import useCurrentAccountStore from "./AccountStore";
import tabOnEnter from "../utils/formNavigationUtils";
import InputElement, {
	CurrencyInput,
	IntegerInput,
	PercentInput,
	SelectorElement,
} from "../features/forms/components/InputElement";
import { LoanPeriod, SaleType } from "../constants/Constants";
import Select from "react-select";
import { vendorTypesOBJ } from "constants/Constants";
import { failAlert } from "../utils/alertUtils";
import { saveVendor } from "api/VendorsAPI";
import { useEffect } from "react";
import AddVendorModal from "../components/modals/AddVendorModal";
import useBuildAccount from "./useBuildAccount";
import {
	calculateFinalDueDate,
	calculateFinalPayment,
	calculateFinanceCharge,
	calculateFirstDueDate,
	calculatePayment,
} from "../features/deal/helperFunctions/SaleFunctions";
import useAccountDefaultStore from "./AccountDefaultsStore";
import { useFormContext } from "react-hook-form";
const IN_DEVELOPMENT = process.env.NODE_ENV === "development";
const AccountAddDetail = () => {
	const { account, editAccount } = useCurrentAccountStore(
		(state) => state,
		shallow
	);
	const acctDefaults = useAccountDefaultStore((state) => state, shallow);
	const getAcct = useBuildAccount();
	const [lenderList, setLenderList] = useState([]);
	const { lenders, editLenders, editLender } = useCurrentAccountStore(
		(state) => state,
		shallow
	);
	const getLendersList = () => {
		let list = [];
		for (const key in lenders) {
			list.push({
				label: lenders[key].name,
				value: lenders[key].ID,
				lender: lenders[key],
			});
		}
		list.push({ label: "None", value: null, lender: null });
		setLenderList(list);
	};
	const selectLender = (option) => {
		const value = option.value;
		if (value === "ADD") {
			toggleLenderModal();
		} else {
			editAccount({ ...account, lenderRecNum: option.value });
			editLender(option.lender);
			setLoanSourceRecNum(option.value);
		}
	};
	// Saves the new lender and add it to the lender list in deal store
	const saveNewLender = (lender) => {
		if (lender != null) {
			const isHidden = false;
			//in order to save add isHidden
			const buildLender = { ...lender, isHidden };
			saveVendor(buildLender).then(
				(res) => {
					const newLender = {
						value: res.data.content.ID,
						label: res.data.content.name,
						lender: res.data.content,
					};
					editLenders([...lenders, res.data.content]);
					setLenderList([...lenderList, newLender]);
					editAccount({ ...account, lenderRecNum: newLender.value });
				},
				(e) => {
					failAlert("Failed to save new lender");
				}
			);
		}
	};

	const toggleLenderModal = () => setOpenAddLenderModal(!openAddLenderModal);
	const [openAddLenderModal, setOpenAddLenderModal] = useState(false);

	const [apr, setApr] = useState(account.apr);
	const [term, setTerm] = useState(account.term);
	const [acctNumber, setAcctNumber] = useState(account.accountNumber);
	const [daysToPay, setDaysToPay] = useState(account.daysToPay);
	const [amtFinanced, setAmtFinanced] = useState(account.amtFinanced);
	const [loanDate, setLoanDate] = useState(account.loanDate);
	const [firstDueDate, setFirstDueDate] = useState(account.firstDueDate);
	const [finalDueDate, setFinalDueDate] = useState(account.finalDueDate);
	const [loanPeriod, setLoanPeriod] = useState(account.period);
	const [payoffMethod, setPayoffMethod] = useState(account.payoffMethod);
	const [loanSourceRecNum, setLoanSourceRecNum] = useState(
		account.loanSourceRecNum
	);
	const [monthlyTax, setMonthlyTax] = useState(account.monthlyTax);
	const [payment, setPayment] = useState(account.regularPayment);
	const [finalPayment, setFinalPayment] = useState(account.finalPayment);
	const [financeCharge, setFinanceCharge] = useState(account.financeCharge);
	const [isCalculate, setIsCalculate] = useState(false);

	useEffect(() => {
		getLendersList();
		// eslint-disable-next-line
	}, []);
	useEffect(() => {
		setDaysToPay(LoanPeriod[loanPeriod].daysInPeriod);
		// eslint-disable-next-line
	}, [loanPeriod]);
	useEffect(() => {
		const firstDueDate_ = calculateFirstDueDate(daysToPay, loanDate);
		setFirstDueDate(firstDueDate_);
		// eslint-disable-next-line
	}, [daysToPay, loanDate]);
	useEffect(() => {
		const finalDueDate_ = calculateFinalDueDate(loanPeriod, term, firstDueDate);
		setFinalDueDate(finalDueDate_);
		setIsCalculate(true);
		// eslint-disable-next-line
	}, [term, firstDueDate]);
	useEffect(() => {
		if (isCalculate) {
			let payment_ = calculatePayment(
				apr / 100,
				loanPeriod,
				term,
				amtFinanced,
				loanDate,
				firstDueDate,
				0
			);

			let finalPmt_ = calculateFinalPayment(
				apr / 100,
				payment_,
				term,
				amtFinanced
			);

			const calculatePayments = () => {
				setPayment(payment_);
				setFinalPayment(finalPmt_);
			};
			calculatePayments();

			let financeCharge_ = calculateFinanceCharge(
				amtFinanced,
				payment_,
				finalPmt_,
				term
			);
			setFinanceCharge(financeCharge_);
		}
		setIsCalculate(false);
		// eslint-disable-next-line
	}, [isCalculate]);
	useEffect(() => {
		setIsCalculate(true);
	}, [amtFinanced, apr]);
	const handleSaveToStore = () => {
		const dailyIntRate =
			account.apr / acctDefaults.acctDefaults.daysInYear / 100;
		const roundedDailyIntRate = parseFloat(dailyIntRate.toFixed(9));

		editAccount({
			...account, // keep any existing fields we haven't changed
			accountNumber: acctNumber,
			loanDate: loanDate,
			amtFinanced: amtFinanced,
			apr: apr,
			loanPeriod: loanPeriod,
			payoffMethod: payoffMethod,
			term: term,
			daysToPay: daysToPay,
			firstDueDate: firstDueDate,
			finalDueDate: finalDueDate,
			regularPayment: payment,
			finalPayment: finalPayment,
			financeCharge: financeCharge,
			monthlyTax: monthlyTax,
			loanSourceRecNum: loanSourceRecNum,
			lastScheduledPmtDate: account.loanDate,
			dailyIntRate: roundedDailyIntRate,
		});
	};

	useEffect(() => {
		handleSaveToStore();
		// eslint-disable-next-line
	}, [
		acctNumber,
		loanDate,
		amtFinanced,
		apr,
		loanPeriod,
		payoffMethod,
		term,
		daysToPay,
		firstDueDate,
		finalDueDate,
		payment,
		finalPayment,
		financeCharge,
		monthlyTax,
		loanSourceRecNum,
	]);
	const methods = useFormContext();

	return (
		<>
			{IN_DEVELOPMENT && (
				<Col sm="1">
					<button
						style={{
							position: "fixed",
							top: "4rem",
							right: "2rem",
							zIndex: "9999",
						}}
						className="btn-sm"
						onClick={() => {
							const acct = getAcct();
							console.log(acct);
						}}
					>
						JSON Acct
					</button>
				</Col>
			)}

			<Card className="my-3">
				<CardHeader className="text-center">
					<CardTitle tag="h3">Account Details</CardTitle>
				</CardHeader>

				<CardBody className="py-0">
					{/* <Form>
				<Label className="h3">Account Details</Label> */}
					<Row className="mx-0 pt-0" onKeyDown={tabOnEnter}>
						<div className="col-xl-6 mb-2">
							<InputElement
								{...methods}
								type="text"
								name="acctNumber"
								label="Account #"
								value={acctNumber}
								colSize="col-xl-12"
								onChange={(e) => setAcctNumber(e.target.value)}
								// onChange={(e) =>
								// 	editAccount({ ...account, accountNumber: e.target.value })
								// }
							/>
						</div>
						<div className="col-xl-6 mb-2">
							<InputElement
								{...methods}
								disableValidation
								value={loanDate}
								name="acctLoanDate"
								label="Loan Date"
								type="date"
								colSize="col-xl-12"
								onChange={(e) => setLoanDate(e.target.value)}
							/>
						</div>
						<div className="col-xl-6 mb-2">
							<CurrencyInput
								wholeRow={false}
								disableValidation
								label="Amount Finance"
								type="currency"
								name="amtFinanced"
								value={amtFinanced}
								colSize="col-xl-12"
								labelClass="size='sm'"
								onChange={(e) => setAmtFinanced(e)}
							/>
						</div>
						<div className="col-xl-6 mb-2">
							<PercentInput
								label={
									"APR (%)"
									// "(" +
									// (account.dailyIntRate * account.principleRemaining).toFixed(
									// 	4
									// ) +
									// " DailyInt)"
								}
								name="financeAPR"
								value={apr / 100}
								disableValidation
								colSize="col-xl-12"
								onChange={(e) => {
									if (Number(loanPeriod) !== 5) {
										if (e.value === "") {
											setApr(0);
										} else {
											setApr(Number(e * 100));
										}
									}
								}}
							/>
						</div>
						<div className="col-xl-6 mb-2">
							<SelectorElement
								disableValidation
								colSize="col-xl-12"
								name="loanPeriod"
								label="Period"
								value={loanPeriod}
								options={LoanPeriod}
								onChange={(e) => {
									setLoanPeriod(e.target.value);
								}}
							/>
						</div>
						<div className="col-xl-6 mb-2">
							<SelectorElement
								name="payoffMethod"
								disabled={true}
								label="Payoff Method"
								colSize="col-xl-12"
								value={payoffMethod}
								onChange={(e) => setPayoffMethod(e.target.value)}
								options={[
									{ value: 0, label: "RULE_OF_78" },
									{ value: 1, label: "SIMPLE_INT" },
									{ value: 2, label: "SCHEDULE_INT" },
								]}
								disableValidation
							/>
						</div>
						<div className="col-xl-6 mb-2">
							<IntegerInput
								disableValidation
								value={term}
								name="term"
								label="Term"
								type="number"
								colSize="col-xl-12"
								onChange={(e) => setTerm(e)}
							/>
						</div>
						<div className="col-xl-6 mb-2 ">
							<IntegerInput
								disableValidation
								value={daysToPay}
								name="daysToPay"
								label="Days to 1st Pmt"
								type="number"
								colSize="col-xl-12"
								onChange={(e) => setDaysToPay(e)}
							/>
						</div>
						<div className="col-xl-6 mb-2">
							<InputElement
								{...methods}
								disableValidation
								readOnly={true}
								type="date"
								name="firstDueDate"
								label="First Due Date"
								value={firstDueDate}
								colSize="col-xl-12"
							/>
						</div>
						<div className="col-xl-6 mb-2">
							<InputElement
								{...methods}
								disableValidation
								readOnly={true}
								type="date"
								name="finalDueDate"
								label="Final Due Date"
								value={finalDueDate}
								colSize="col-xl-12"
							/>
						</div>
						<div className="col-xl-6 mb-2">
							<CurrencyInput
								wholeRow={false}
								disableValidation
								readOnly={true}
								type="currency"
								name="regularPayment"
								label={
									(account.term > 1 ? account.term - 1 : 1) + " Payments of  "
								}
								value={payment}
								colSize="col-xl-12"
								labelClass="size='sm'"
							/>
						</div>

						{account.taxType === 1 ? (
							<div className="col-xl-6 mb-2">
								<CurrencyInput
									wholeRow={false}
									readOnly={true}
									label="With monthly Tax "
									type="currency"
									name="monthlyTax"
									value={monthlyTax}
									disableValidation
									colSize="col-xl-12"
									labelClass="size='sm'"
								/>
							</div>
						) : (
							""
						)}

						<div className="col-xl-6 mb-2">
							<CurrencyInput
								wholeRow={false}
								value={finalPayment}
								readOnly={true}
								name="finalPayment"
								type="Currency"
								label="Final Payment"
								disableValidation
								colSize="col-xl-12"
								labelClass="size='sm'"
							/>
						</div>
						<div className="col-xl-6 mb-2">
							<CurrencyInput
								wholeRow={false}
								disableValidation
								label="Finance Charge"
								colSize="col-xl-12"
								labelClass="size='sm'"
								readOnly={true}
								type="currency"
								name="financeCharge"
								value={financeCharge}
							/>
						</div>
						<div className="col-xl-12 mb-2">
							<div>
								<Label>Lender</Label>
								<Select
									noOptionsMessage={() => "No Lenders available"}
									value={{
										value: loanSourceRecNum,
										label:
											lenderList.filter(
												(element) => element.value === loanSourceRecNum
											)[0]?.label || "None",
									}}
									options={[
										...lenderList,
										{ value: "ADD", label: "Add new lender" },
									]}
									onChange={(e) => selectLender(e)}
								/>
								<AddVendorModal
									title="Add Lender"
									vendorType={vendorTypesOBJ.LENDER.value}
									vendorLabel={vendorTypesOBJ.LENDER.label}
									addVendor={saveNewLender}
									toggle={toggleLenderModal}
									modal={openAddLenderModal}
								/>
							</div>
						</div>
					</Row>
				</CardBody>
			</Card>
		</>
	);
};

export default AccountAddDetail;

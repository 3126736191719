import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Form, Button, FormGroup } from "reactstrap";
import { useFromUser } from "stores/LocalStorageHelper";
import {
	CurrencyInput,
	PercentInput,
} from "features/forms/components/InputElement";
import InputWrapper from "features/forms/components/InputWrapper";
import Selector from "features/inventory/subFeatures/selector/Selector";
import useCurrentDealStore from "stores/DealStore";
import useCurrentRecapStore from "stores/RecapStore";
import { commTypeList } from "constants/Constants";
import DealSalesmanDropDown from "../../dealDetails/DealSalesmanDropdown";

const CommissionForm = ({ currentIndex, setCurrentIndex, toggle }) => {
	const { dealRecNum, commissions, editCommissions, salesPeople, calculate } =
		useCurrentDealStore((state) => ({
			dealRecNum: state.deal.ID,
			commissions: state.commissions,
			editCommissions: state.editCommissions,
			salesPeople: state.salesPeople,
			calculate: state.calculate,
		}));

	const { fiGross, salesGross, totalGross, grossProfit } = useCurrentRecapStore(
		(state) => ({
			fiGross: state.fiGross,
			salesGross: state.salesGross,
			totalGross: state.totalGross,
			grossProfit: state.grossProfit,
		})
	);

	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");

	// Local state
	const [personRecNum, setPersonRecNum] = useState(null);
	const [commType, setCommType] = useState(10);
	const [commAmount, setCommAmount] = useState(0);
	const [bonus, setBonus] = useState(0);
	const [other, setOther] = useState(0);
	const [commPercent, setCommPercent] = useState(0);
	const [totalComm, setTotalComm] = useState(0);
	const [commissionable, setCommissionable] = useState(0);
	const [buttonText, setButtonText] = useState("Add Commission");
	const [minCommission, setMinCommission] = useState(0);
	const [fixedAmount, setFixedAmount] = useState(0);

	// Change button text
	const changeText = (text) => setButtonText(text);

	// Clear fields
	const clearFields = () => {
		changeText("Add Commission");
		setPersonRecNum(null);
		setCommType(10);
		setFixedAmount(0);
		setCommAmount(0);
		setBonus(0);
		setOther(0);
		setCommPercent(0);
		setCurrentIndex(commissions.length);
	};

	// Get ID
	const getID = () => {
		const commission = commissions[currentIndex];
		return commission?.id ?? null;
	};

	// Add or edit commission
	const addCommission = () => {
		const id = getID();
		const newCommission = {
			id,
			personRecNum,
			commType,
			commAmount: parseFloat(commAmount),
			bonus: parseFloat(bonus),
			other: parseFloat(other),
			commPercent: parseFloat(commPercent),
			fixedAmount: parseFloat(fixedAmount),
			totalComm: parseFloat(totalComm),
			dealRecNum,
			dealerID,
			locationID,
		};

		const commList = () => {
			let obj = [...commissions];
			obj[currentIndex] = newCommission;
			return obj;
		};

		editCommissions(commList());
		clearFields();
		calculate();
		toggle();
	};

	// Calculate commission
	const calculateCommission = useCallback(() => {
		let amount = 0;

		switch (Number(commType)) {
			case commTypeList.SALE.value:
				amount = twoDecimal(salesGross * commPercent);
				setCommissionable(salesGross);
				break;
			case commTypeList.FI.value:
				amount = twoDecimal(fiGross * commPercent);
				setCommissionable(fiGross);
				break;
			case commTypeList.TOTAL.value:
				amount = twoDecimal(totalGross * commPercent);
				setCommissionable(totalGross);
				break;
			case commTypeList.NETPROFIT.value:
				amount = twoDecimal(grossProfit * commPercent);
				setCommissionable(grossProfit);
				break;
			default:
				break;
		}

		if (fixedAmount > 0) amount += twoDecimal(fixedAmount);
		if (amount < minCommission) amount = twoDecimal(minCommission);
		setCommAmount(amount);

		const totalCommission = amount + parseFloat(bonus) + parseFloat(other);
		setTotalComm(totalCommission);
		return totalCommission;
	}, [
		commType,
		commPercent,
		salesGross,
		fiGross,
		totalGross,
		grossProfit,
		fixedAmount,
		minCommission,
		bonus,
		other,
	]);

	// Convert value to two decimal places
	const twoDecimal = (value) => {
		return parseFloat(parseFloat(value).toFixed(2));
	};

	useEffect(() => {
		calculateCommission();
		calculate();
		// eslint-disable-next-line
	}, [commType, commPercent, bonus, other, fixedAmount]);

	useEffect(() => {
		const obj = commissions[currentIndex] || {};
		setPersonRecNum(obj?.personRecNum || null);
		setCommType(obj?.commType || 10);
		setFixedAmount(obj?.fixedAmount || 0);
		setCommPercent(obj?.commPercent || 0);
		setCommAmount(obj?.commAmount || 0);
		setBonus(obj?.bonus || 0);
		setOther(obj?.other || 0);
		setTotalComm(obj?.totalComm || 0);
		changeText("Save");
		return clearFields;
		// eslint-disable-next-line
	}, [currentIndex]);

	useEffect(() => {
		if (commissions.length === currentIndex) {
			const salesPerson = salesPeople.find((sp) => sp.ID === personRecNum);
			if (salesPerson) {
				setFixedAmount(salesPerson.commissionFixedAmount);
				setMinCommission(salesPerson.commissionMinimum);
				setCommPercent(salesPerson.commissionPercent);
			}
		}
		calculate();
		// eslint-disable-next-line
	}, [personRecNum]);

	return (
		<Form className="px-0">
			<InputWrapper
				inputComponents={[
					<FormGroup key="Person" className="col-md-6">
						<DealSalesmanDropDown
							key="dealSalesmanDropDown"
							label="Person"
							isComm={true}
							setPersonRecNum={setPersonRecNum}
							personRecNum={personRecNum}
						/>
					</FormGroup>,
					<FormGroup key="commType" className="col-md-3">
						<Selector
							selectedOption={commType}
							handleChange={(e) => setCommType(e)}
							selectType="commType"
							selectLabel="Commission Type"
							labelClass=" required"
						/>
					</FormGroup>,
					<CurrencyInput
						key="commissionable"
						value={commissionable}
						readOnly
						onChange={setCommissionable}
						name="commissionable"
						label="Commissionable"
						type="Currency"
						disableValidation
					/>,
					<PercentInput
						key="commPercent"
						value={commPercent}
						onChange={setCommPercent}
						name="commPercent"
						label="Percent"
						disableValidation
					/>,
					<CurrencyInput
						key="fixedAmount"
						value={fixedAmount}
						onChange={setFixedAmount}
						name="fixedAmount"
						label="Fixed Amount"
						type="Currency"
						disableValidation
					/>,
					<CurrencyInput
						key="minCommission"
						readOnly
						value={minCommission}
						onChange={setMinCommission}
						name="Minimum"
						label="Minimum"
						type="Currency"
						disableValidation
					/>,
					<CurrencyInput
						key="commAmount"
						value={commAmount}
						onChange={setCommAmount}
						readOnly
						name="commAmount"
						label="Amount"
						type="Currency"
						disableValidation
					/>,
					<CurrencyInput
						key="bonus"
						value={bonus}
						onChange={setBonus}
						name="bonus"
						label="Bonus"
						type="Currency"
						disableValidation
					/>,
					<CurrencyInput
						key="other"
						value={other}
						onChange={setOther}
						name="other"
						label="Other"
						type="Currency"
						disableValidation
					/>,
					<CurrencyInput
						key="totalComm"
						value={totalComm}
						onChange={setTotalComm}
						readOnly
						name="totalComm"
						label="Total"
						type="Currency"
						disableValidation
					/>,
				]}
				buttons={
					<div className="d-flex justify-content-center mt-2">
						<Button size="sm" color="success" onClick={addCommission}>
							&nbsp;
							{buttonText === "Save" ? (
								<i className="nc-icon nc-check-2"></i>
							) : (
								<i className="nc-icon nc-simple-add"></i>
							)}
							&nbsp;{buttonText}
						</Button>
						<Button className="btn btn-link btn-md" onClick={clearFields}>
							Reset
						</Button>
					</div>
				}
			/>
		</Form>
	);
};

CommissionForm.propTypes = {
	currentIndex: PropTypes.number.isRequired,
	setCurrentIndex: PropTypes.func.isRequired,
	toggle: PropTypes.func.isRequired,
};

export default CommissionForm;

import React, { useState, useEffect } from "react";
import { useFromUser } from "stores/LocalStorageHelper";
import dayjs from "dayjs";
import { Button, Form } from "reactstrap";
import InputElement, {
	CurrencyInput,
} from "../features/forms/components/InputElement";
import InputWrapper from "features/forms/components/InputWrapper";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import { setLocale } from "yup";
import { useForm, FormProvider } from "react-hook-form";
import shallow from "zustand/shallow";
import useCurrentAccountStore from "./AccountStore";
import { SelectorElement } from "../features/forms/components/InputElement";
import {
	PaymentMode,
	PaymentStatus,
	PaymentType,
	PaymentTypeToModeMapping,
	paymentMethod,
} from "../constants/Constants";
import AccountHook from "./AccountHook";

const AcctEditPayment = (props) => {
	const save = AccountHook();
	const { currentIndex, toggle } = props;
	const { payments, editPayments, account } = useCurrentAccountStore(
		(state) => state,
		shallow
	);
	const today = dayjs().format("YYYY-MM-DD");
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	const userID = useFromUser("ID");

	const [payment, setPayment] = useState(payments[currentIndex]);
	const [datePaid, setdatePaid] = useState(today);
	const [amtPaid, setAmtPaid] = useState(0);
	const [convenienceFee, setConvenienceFee] = useState(0);

	const [receiptNo, setreceiptNo] = useState("");
	const [method, setMethod] = useState("cash");

	const [checkNo, setCheckNo] = useState("");
	const [pmtType, setPmtType] = useState("Loan");
	const [pmtMode, setPmtMode] = useState(payments[currentIndex]?.pmtMode || 0);
	const [status, setStatus] = useState("completed");
	const [statusReason, setStatusReason] = useState("");
	const [note, setNote] = useState("");
	const [description, setDescription] = useState("");

	const [newPmt, setNewPmt] = useState(true);

	const [filteredPaymentMode, setFilteredPaymentMode] = useState([]);
	const [formTitle, setFormTitle] = useState([]);

	const [loanPaid, setLoanPaid] = useState(0);
	const [lateFeePaid, setLateFeePaid] = useState(0);
	const [adjFeePaid, setAdjFeePaid] = useState(0);
	const [pickupPaid, setPickupPaid] = useState(0);

	const handleMethod = (e) => {
		setMethod(e);
	};

	useEffect(() => {
		if (currentIndex < payments.length) {
			setNewPmt(false);
			setFormTitle("Edit Payment ");

			setdatePaid(payment?.datePaid || "");
			setAmtPaid(payment?.amtPaid || "");
			setConvenienceFee(payment?.convenienceFee || "");

			setreceiptNo(payment?.receiptNo || "");
			setCheckNo(payment?.checkNo);
			setMethod(payment?.method);
			setPmtType(payment?.pmtType);
			setPmtMode(payment?.pmtMode);
			setNote(payment?.note);
			setDescription(payment?.description);
			setStatus(payment?.status);
			setStatusReason(payment?.statusReason);

			setLateFeePaid(payment?.lateFeepaid || 0.0);
			setLoanPaid(payment?.principalPaid + payment?.interestPaid);
			//			setDebitPaid(payment?.debitPaid || 0.0);
			setAdjFeePaid(payment?.adjFeePaid || 0.0);
			setPickupPaid(payment?.pickupPaid || 0.0);
		} else {
			const newPayment = {
				acctRecnum: account.acctRecnum,
				amtPaid,
				lateFeepaid: lateFeePaid,
				adjFeePaid,
				convenienceFee,
				datePaid,
				receiptNo,
				method,
				checkNo,
				pmtType,
				pmtMode,
				status: status,
				statusReason,
				note,
				description,
				currentIndex,
				dealerID,
				locationID,
				id: null,
				userRecNum: userID,
				createOn: new Date(),
				appVersion: "2.0",
			};
			setPayment(newPayment);
			// 	clearFields();
			setNewPmt(true);
			setFormTitle("Add Payment");
		}
		// eslint-disable-next-line
	}, []);

	const updatePayment = () => {
		const getID = () => {
			if (payments[currentIndex] === undefined) {
				return null;
			} else if (payments[currentIndex].id === undefined) {
				return null;
			} else {
				return payments[currentIndex].id;
			}
		};
		const ID = getID();
		const newPayment = {
			...payment,
			accountRecNum: account.id,
			amtPaid: parseFloat(amtPaid),
			lateFeepaid: lateFeePaid,
			adjFeePaid,
			convenienceFee: parseFloat(convenienceFee),
			datePaid,
			receiptNo,
			method,
			checkNo,
			pmtType,
			pmtMode,
			status: status,
			statusReason,
			note,
			description,
			currentIndex,
			dealerID,
			locationID,
			id: ID,
			userRecNum: userID,
		};

		const paymentList = () => {
			let obj = payments;
			obj[currentIndex] = newPayment;
			return obj;
		};
		const newPayments = paymentList();
		editPayments(newPayments);
		save();

		toggle();
	};
	// Define custom error messages for different error types
	setLocale({
		mixed: {
			required: "Required",
		},
		string: {
			// eslint-disable-next-line
			max: "Must be ${max} characters or less",
			length: "Required format is YYYY/MM/DD",
		},
	});

	// Define rules for each input field
	const schema = yup.object().shape({});

	// Define form validation parameters
	const methods = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});
	useEffect(() => {
		if (pmtType && PaymentTypeToModeMapping[pmtType]) {
			const modeValues = PaymentTypeToModeMapping[pmtType];
			const filteredModes = PaymentMode.filter((mode) =>
				modeValues.includes(mode.value)
			);
			setFilteredPaymentMode(filteredModes);
		} else {
			setFilteredPaymentMode(PaymentMode);
		}
	}, [pmtType]);

	// Avoid running setAmtPaid unless it's necessary
	useEffect(() => {
		if (pmtMode === "99" || pmtMode === 99) {
			// Ensure amtPaid only gets updated when it has changed
			const newLoanPaid = amtPaid - lateFeePaid - adjFeePaid;
			setLoanPaid(newLoanPaid); // Update amtPaid only if it's different
		}
	}, [loanPaid, lateFeePaid, adjFeePaid, pmtMode, amtPaid]);
	return (
		<>
			<FormProvider className="" {...methods}>
				<Form>
					<InputWrapper
						formTitle={formTitle}
						inputComponents={[
							<InputElement
								{...methods}
								value={datePaid}
								onChange={(e) => {
									setdatePaid(e.target.value);
								}}
								name="datePaid"
								label="Date Paid"
								type="date"
							/>,
							<SelectorElement
								{...methods}
								name="pmtType"
								label="Payment Type"
								value={pmtType}
								onChange={(e) => setPmtType(e.target.value)}
								options={PaymentType}
							/>,
							<SelectorElement
								{...methods}
								name="pmtmode"
								label="Payment Mode"
								value={pmtMode}
								onChange={(e) => setPmtMode(e.target.value)}
								options={filteredPaymentMode}
							/>,
							<SelectorElement
								{...methods}
								name="method"
								label="Methods"
								value={method}
								onChange={(e) => handleMethod(e.target.value)}
								options={paymentMethod}
							/>,

							<CurrencyInput
								disableValidation
								value={amtPaid}
								onChange={setAmtPaid}
								name="amountPaid"
								label="Amount Paid"
								type="number"
							/>,
							(pmtMode === "99" || pmtMode === 99) && (
								<>
									<CurrencyInput
										disableValidation
										value={lateFeePaid}
										onChange={(e) => {
											setLateFeePaid(e);
										}}
										name="lateFeePaid"
										label="Late Fee Paid"
										type="number"
									/>
									,
									<CurrencyInput
										disableValidation
										value={adjFeePaid}
										onChange={(e) => {
											setAdjFeePaid(e);
										}}
										name="adjFeePaid"
										label="Adjustment Paid"
										type="number"
									/>
									,
									<CurrencyInput
										disableValidation
										readOnly={pmtMode === "99" || pmtMode === 99}
										value={loanPaid}
										onChange={(e) => {
											setLoanPaid(e);
										}}
										name="loanPaid"
										label="Loan Paid"
										type="number"
									/>
								</>
							),

							<CurrencyInput
								disableValidation
								value={convenienceFee}
								onChange={setConvenienceFee}
								name="convenienceFee"
								label="Convenience Fee"
								type="number"
							/>,
							<CurrencyInput
								disableValidation
								value={amtPaid + convenienceFee}
								//onChange={setAmtPaid}
								name="totalPaid"
								label="Total Paid"
								type="number"
							/>,
							<SelectorElement
								{...methods}
								name="paymentStatus"
								label="Payment Status"
								value={status}
								onChange={(e) => setStatus(e.target.value)}
								options={PaymentStatus}
							/>,
							<InputElement
								{...methods}
								value={receiptNo}
								onChange={(e) => {
									setreceiptNo(e.target.value);
								}}
								name="receiptNo"
								label="Receipt #"
								type="text"
							/>,
							<InputElement
								{...methods}
								value={checkNo}
								onChange={(e) => {
									setCheckNo(e.target.value);
								}}
								name="checkNo"
								label="Reference No"
								type="text"
							/>,
							<InputElement
								{...methods}
								value={statusReason}
								onChange={(e) => {
									setStatusReason(e.target.value);
								}}
								name="statusReason"
								label="Status Reason"
								type="text"
							/>,
							<InputElement
								{...methods}
								value={description}
								onChange={(e) => {
									setDescription(e.target.value);
								}}
								colSize="col-sm-9"
								name="description"
								label="Description"
								type="text"
							/>,

							<InputElement
								{...methods}
								value={note}
								colSize="col-sm-12"
								onChange={(e) => {
									setNote(e.target.value);
								}}
								name="note"
								label="Note"
								type="textarea"
							/>,
							<InputElement
								{...methods}
								value={account.accountNumber}
								name="accountNumber"
								label="Account Number"
								type="text"
							/>,
							<InputElement
								{...methods}
								value={account.id}
								name="accountID"
								label="Account ID"
								type="text"
							/>,
							<InputElement
								{...methods}
								value={payment.id}
								name="paymentID"
								label="Payment ID"
								type="text"
							/>,

							<Button
								active={account.status === 0}
								color="primary"
								onClick={methods.handleSubmit(updatePayment)}
							>
								Save
							</Button>,
						]}
					/>
				</Form>
			</FormProvider>
		</>
	);
};

export default AcctEditPayment;

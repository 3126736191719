import React, { useEffect, useState } from "react";

import { showApiError } from "../utils/errorRoutingUtils";
import { useHistory } from "react-router-dom";

import shallow from "zustand/shallow";
import useCurrentAccountStore from "./AccountStore";
import useAccountDefaultStore from "./AccountDefaultsStore";
import useSetAccount from "./useSetAccount";
import { PATHS, vendorTypesOBJ } from "../constants/Constants";
import { retrieveFailAlert } from "../utils/alertUtils";
import useRoute from "../hooks/useRoute";
import { useFromUser } from "../stores/LocalStorageHelper";
import Loading from "../components/loadingSpinner/Loading";
import AccountCustomerHeader from "./AccountCustomerHeader";
import AccountAddDetail from "./AccountAddDetail";
import { getAcctDefaults, getNewAccount } from "./AccountAPI";
import dayjs from "dayjs";
import { Row } from "reactstrap";
import AccountAddDeferred from "./AccountAddDeferred";
import AccountAddVehicle from "./AccountAddVehicle";
import { FormProvider, useForm } from "react-hook-form";
import { AccountSaveButton } from "./AccountSaveButton";
import { getVendorListByType } from "../api/VendorsAPI";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";

const AccountAddPage = () => {
	const today = dayjs().format("YYYY-MM-DD");
	// Grab information from local storage
	const dcLoginID = useFromUser("ID");
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	const firstName = useFromUser("firstName");
	const lastName = useFromUser("lastName");
	// Routes
	const history = useHistory();

	const { editAcctDefaults } = useAccountDefaultStore(
		(state) => state,
		shallow
	);
	//const acctID = prop.location.state?.acctID;
	const acctListPage = useRoute(PATHS.ACCT_LIST);
	const setAccount = useSetAccount();

	// Account store
	const acctObj = useCurrentAccountStore((state) => state, shallow);
	const [isLoading, setIsLoading] = useState(true);

	const schema = yup.object().shape({
		acctNumber: yup.string().required().max(50),
		// loanDate: yup.string().required(),
		// amtFinanced: yup.string().required(),
		// apr: yup.string().required(),
		// loanPeriod: yup.string().required(),
		// payoffMethod: yup.string().required(),
		// term: yup.string().required(),
		// daysToPay: yup.string().required(),
	});
	const methods = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});

	const getNewData = () => {
		Promise.all([
			getAcctDefaults(locationID),
			getNewAccount(),
			getVendorListByType(locationID, vendorTypesOBJ.LENDER.value),
		]).then(
			(res) => {
				editAcctDefaults(res[0].data.content);
				setAccount(res[1]);

				let newAccount = { ...res[1].data.content.account };

				newAccount.locationID = locationID;
				newAccount.dealerID = dealerID;
				newAccount.createdBy = lastName + ", " + firstName;
				newAccount.modifiedBy = lastName + ", " + firstName;
				newAccount.modifiedById = dcLoginID;
				newAccount.createdById = dcLoginID;
				newAccount.createdOn = today;
				newAccount.modifiedOn = today;
				newAccount.period = 3;
				newAccount.daysToPay = 30;
				newAccount.firstDueDate = "";
				newAccount.loanDate = today;
				newAccount.type = "Car";
				newAccount.saleType = 0;
				newAccount.apr = 0;
				newAccount.amtFinanced = 0;
				newAccount.term = 36;
				newAccount.payoffMethod = 1;
				newAccount.notes = "";
				newAccount.pickupOnly = false;
				newAccount.isTaxDeferred = false;
				newAccount.totalCost = 0;
				newAccount.status = 0;
				newAccount.subStatus = "Current";
				newAccount.firstDueDate = dayjs().add(30, "days").format("YYYY-MM-DD");

				acctObj.editAccount(newAccount);
				acctObj.editLenders(res[2].data.content);

				setIsLoading(false);
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
					history.push(acctListPage);
				}
			}
		);
	};

	useEffect(() => {
		if (isLoading) {
			getNewData();
		}

		return () => {
			acctObj.reset();
		};
	}, []);
	return (
		<div>
			<div className="col-md-12 px-0 content">
				{(isLoading && <Loading />) || (
					<FormProvider {...methods}>
						<Row className="">
							<div className="col-md-4">
								<AccountCustomerHeader />

								{/* <AccountInventoryHeader /> */}
								<AccountAddVehicle />
							</div>
							<div className="col-md-3">
								<AccountAddDetail />
							</div>
							<div className="col-md-3">
								<AccountAddDeferred />
							</div>
						</Row>
						<AccountSaveButton />
					</FormProvider>
				)}
			</div>
		</div>
	);
};

export default AccountAddPage;

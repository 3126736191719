import React from "react";
import { Button } from "reactstrap";
import { ADD, TIME_FORMAT } from "./NoteConstants";
import dayjs from "dayjs";
import { useFromUser } from "../../stores/LocalStorageHelper";

const NoteModalButton = ({
	index,
	lNote,
	notes,
	editNotes,
	setButtonText,
	clearFields,
	disableButtons,
	saveNotes, // Passed from parent
	toggle,
}) => {
	const today = dayjs().format(TIME_FORMAT);
	const firstName = useFromUser("firstName");
	const lastName = useFromUser("lastName");

	const name = firstName + " " + lastName;

	// Handles the add/save button click
	const handleAddOrSave = () => {
		if (!lNote || lNote.trim() === "") {
			return; // Don't proceed if there's no note to save
		}

		let createdOn = notes[index]?.created || today;
		let createdBy = notes[index]?.createdBy || name;
		let modifiedBy = name;

		const entry = {
			createdOn,
			createdBy,
			modifiedOn: today,
			modifiedBy,
			description: lNote,
		};

		const newNotes = [...notes]; // Create a new array by spreading the current notes

		if (index >= newNotes.length) {
			newNotes.push(entry); // If index is out of bounds, push a new entry
		} else {
			newNotes[index] = entry; // Otherwise, update the specific index
		}

		console.log("Updated newNotes:", newNotes); // Ensure the notes are correct here

		setButtonText(ADD);

		// Add logging here to track the state changes
		editNotes(newNotes); // Update the notes state immutably

		// Log the state directly after calling editNotes
		console.log("State after editNotes:", newNotes); // Verify if the state changes here

		clearFields();
		saveNotes(); // Trigger the save after updating the notes
		toggle(); // Close the modal or form
	};

	return (
		<div className="d-flex justify-content-center mt-2">
			<Button
				className="btn-primary btn-md"
				readOnly={disableButtons}
				color="primary"
				onClick={handleAddOrSave} // Only save when the user clicks this button
			>
				Save Note
			</Button>
		</div>
	);
};

export default NoteModalButton;

import shallow from "zustand/shallow";
import useCurrentAccountStore from "./AccountStore";

export const useBuildAccount = () => {
	const acctObj = useCurrentAccountStore((state) => state, shallow);

	const getAcct = () => {
		const n = [...acctObj.account.notes];
		console.log(acctObj.account.notes);
		let newAcct = { ...acctObj, account: { ...acctObj.account, notes: [] } };
		newAcct.account.notes = n.length > 0 ? JSON.stringify(n) : "[]";
		return newAcct;
	};

	return getAcct;
};

export default useBuildAccount;

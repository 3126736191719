/*!

=========================================================
* DealerClick 2.0 - v1.0
=========================================================

* Product Page: https://dealerclick.com
* Copyright 2020 RoarinLion (https://dealerclick.com)

* Coded by DealerClick Software ))

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useEffect, useContext } from "react";
import { Button } from "reactstrap";

import useCurrentDealStore from "stores/DealStore";
import useCurrentSaleStore from "stores/SaleStore";
import Camera from "assets/img/placeholder.png";
import InventoryTable from "../../../../components/tables/InventoryTable";
import Loading from "../../../../components/loadingSpinner/Loading";

import { getVehicleDetails } from "api/InventoryAPI";
import { getVehicleListByStatus } from "api/InventoryAPI";
import { retrieveFailAlert } from "../../../../utils/alertUtils";
import { showApiError } from "../../../../utils/errorRoutingUtils";
import { useFromUser } from "stores/LocalStorageHelper";
import shallow from "zustand/shallow";
import dayjs from "dayjs";
import { WholesaleInvsContext } from "../dealTabs/wholesaleInventorys/WholeSaleInvsContext";
import useCurrentAccountStore from "../../../../account/AccountStore";

const DealInventoryListData = ({ toggle, mainForm = "Deal" }) => {
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");

	const [data, setData] = useState([]);
	const [loadingList, setLoadingList] = useState(true);

	// Deal store
	const { editVehicle, editCarRecNum } = useCurrentDealStore(
		(state) => ({
			editVehicle: state.editVehicle,
			editCarRecNum: state.editCarRecNum,
		}),
		shallow
	);

	// Sale store
	const { saleType, editPrice, editDown } = useCurrentSaleStore(
		(state) => ({
			saleType: state.saleType,
			editPrice: state.editPrice,
			editDown: state.editDown,
		}),
		shallow
	);

	/**
	 *	These functions above useEffect() will need to be included in useCallBack() to avoid the warning on compile
	 *	until we figure this out, ignore by adding "// eslint-disable-next-line"
	 *	https://stackoverflow.com/questions/55840294/how-to-fix-missing-dependency-warning-when-using-useeffect-react-hook
	 */

	// Adds vehicle information to the deal store
	const accessDealHandler = (vehicle) => {
		console.log("Adding vehicle to deal: " + vehicle);

		getVehicleDetails(vehicle.inventoryID).then(
			(res) => {
				console.log(res.data.content);
				editVehicle(res.data.content);
				editCarRecNum(vehicle.inventoryID);

				//check to see if saleType is wholesale or retail
				// and set the price accordingly
				if (saleType === 1) {
					editPrice(res.data.content.wholesalePrice);
				} else {
					editPrice(vehicle.price);
				}
				console.log(vehicle);
				console.log(res.data.content);

				editDown(res.data.content?.downPmt);
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
				}
			}
		);
	};
	const acctObj = useCurrentAccountStore((state) => state, shallow);
	const accessAccountHandler = (vehicle) => {
		console.log("Adding vehicle to Account : " + vehicle);
		getVehicleDetails(vehicle?.inventoryID).then(
			(res) => {
				acctObj.editVehicle(res.data.content);
				console.log(vehicle);
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
				}
			}
		);
	};
	const accessBulkHandler = (vehicle) => {
		console.log("Adding vehicle to Bulk deal: " + vehicle);
		getVehicleDetails(vehicle?.inventoryID).then(
			(res) => {
				console.log(res.data.content);
				editInventoryID(vehicle.inventoryID);
				editBulkVehicle(res.data.content);
				editSalesPrice(
					res.data.content.wholesalePrice === 0 ||
						res.data.content.wholesalePrice === null
						? res.data.content.askingPrice
						: res.data.content.wholesalePrice
				);
				console.log(vehicle);
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
				}
			}
		);
	};
	const dataFormat = (content) => {
		const container = Object.keys(content).map((key) => {
			let container = {};
			let thumbnail = content[key].thumbnail;

			container.thumbnail = (
				<img
					className="zoom"
					alt="vehicle"
					src={`${content[key].thumbnail}?lastmod=${Date.now()}`}
					onError={(e) => {
						e.target.onerror = null;
						e.target.src = Camera;
					}}
				/>
			);
			container.thumbnailURL = thumbnail;
			container.inventoryID = content[key].inventoryID;
			container.stockNo = content[key].stockNo;
			container.year = content[key].year;
			container.make = content[key].make;
			container.model = content[key].model;
			container.vin = content[key].vin;
			container.status = content[key].vehicleStatus;
			container.stockAndVin =
				content[key].stockNo +
				" " +
				dayjs(content[key].dateIn).format("MM/DD/YYYY");

			// const vehicle = [
			// 	content[key].year,
			// 	content[key].make,
			// 	content[key].model,
			// ];
			container.vehicle =
				content[key].year + " " + content[key].make + " " + content[key].model;
			container.vehicleSearch =
				container.vehicle + content[key].vin + content[key].license;
			container.downPmt = content[key].downPmt;
			container.type = content[key].type;
			//			container.vehicle = vehicle.join("\n");
			const toDay = new Date();
			const start = content[key].dateIn;
			container.start = dayjs(content[key].dateIn).format("MM/DD/YYYY");

			let end = content[key].dateOut === null ? toDay : content[key].dateOut;
			end = dayjs(end);
			if (content[key].dateOut === null) {
				container.end = "";
			} else {
				container.end = end.format("MM/DD/YYYY");
			}

			const age = end.diff(start, "day");
			container.age = age;

			container.askingPrice =
				"$" +
				parseFloat(content[key].price)
					.toFixed(2)
					.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

			container.Actions = (
				<>
					<Button
						className="zoom btn-md btn btn-link btn-primary"
						onClick={() => {
							if (mainForm === "Deal") {
								accessDealHandler(content[key]);
							} else if (mainForm === "Account") {
								accessAccountHandler(content[key]);
							} else {
								accessBulkHandler(content[key]);
							}
							toggle();
						}}
					>
						<i className="icon-big text-center nc-icon nc-briefcase-24" /> Add
						<span className="noMobile"> to Deal </span>
					</Button>
				</>
			);
			return container;
		});
		return container;
	};

	const getListData = () => {
		console.log("ID: " + dealerID + " " + locationID);
		const status = "Available,Pending";
		if (dealerID !== "" && locationID !== "") {
			getVehicleListByStatus(dealerID, locationID, status).then(
				(response) => {
					console.log(response.data.content);
					const result = dataFormat(response.data.content);
					setData(result);
					setLoadingList(false);
				},
				(err) => {
					if (!err.isGeneralError) {
						showApiError(err, retrieveFailAlert);
					}
				}
			);
		}
	};

	useEffect(() => {
		setLoadingList(true);
		getListData();
		// eslint-disable-next-line
	}, []);

	return (
		<div className="">
			{loadingList && <Loading />}
			{!loadingList && <InventoryTable data={data} />}
		</div>
	);
};

export default DealInventoryListData;

import React from "react";
import { Col, Input, Label, Row } from "reactstrap";
import shallow from "zustand/shallow";

import useCurrentAccountStore from "./AccountStore";

//import NumberFormat from "react-number-format";
import Camera from "assets/img/placeholder.png";
import { useFromUser } from "stores/LocalStorageHelper";
import { FTP_IMAGE_URL } from "constants/Constants";
import OpenModalButton from "../features/deal/components/OpenModalButton";
import ChildrenModal from "../components/modals/ChildrenModal";
import AccountAddVehicle from "./AccountAddVehicle";

const AccountInventoryHeader = () => {
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	const { account } = useCurrentAccountStore((state) => state, shallow);
	const getThumbnail = () => {
		if (account.vin !== "") {
			return (
				FTP_IMAGE_URL +
				"/" +
				dealerID +
				"/" +
				locationID +
				"/" +
				account.vin +
				"-0.jpg"
			);
		} else {
			return "";
		}
	};
	const toggleEdit = () => {
		setOpen(!open);
	};

	const [open, setOpen] = React.useState(false);

	return (
		<div>
			<div className="deal-vehicle">
				<div className="mb-2 text-center">
					<h3 className="mb-3">
						{account.year + " " + account.make + " " + account.model}
					</h3>
					<img
						className="dealImg mb-2 zoom"
						src={`${getThumbnail()}?lastmod=${Date.now()}`}
						onError={(e) => {
							e.target.onerror = null;
							e.target.src = Camera;
						}}
						alt="Car"
					/>
					<Row className="text-center">
						<Col sm="12" md="6" lg="6" xl="6">
							<Label>
								Stock
								<Input disabled value={account.stockNum} />
							</Label>
						</Col>
						<Col sm="12" md="6" lg="6" xl="6">
							<Label>
								VIN
								<Input disabled value={account.vin} />
							</Label>
						</Col>
					</Row>
				</div>
				{/* <AccountInventoryHeader /> */}
				<Col md="12" className="text-center mt-half">
					<div className="mx-auto">
						<OpenModalButton
							iconClass="nc-icon nc-align-center"
							buttonClass="btn-sm btn-primary"
							onClick={toggleEdit}
							buttonText="View"
						/>
					</div>
				</Col>
			</div>
			<ChildrenModal
				size="lg"
				modalTitle="Vehicle Information"
				modal={open}
				toggle={toggleEdit}
				children={
					<Row>
						<Col sm="12" className="text-center">
							<AccountAddVehicle />
						</Col>
					</Row>
				}
			/>
		</div>
	);
};

export default AccountInventoryHeader;

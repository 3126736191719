import React, { useState, useEffect } from "react";
import {
	Button,
	Label,
	Card,
	Col,
	CardBody,
	CardHeader,
	CardTitle,
	Row,
	Form,
} from "reactstrap";
import { getAcctDefaults, saveAcctDefaults } from "./AccountAPI";
import { showApiError } from "../utils/errorRoutingUtils";
import InputElement, {
	CheckBoxElement,
	CurrencyInput,
	IntegerInput,
	PercentInput,
	SelectorElement,
} from "../features/forms/components/InputElement";
import {
	retrieveFailAlert,
	saveFailAlert,
	saveSuccessAlert,
} from "../utils/alertUtils";
import { useFromUser } from "../stores/LocalStorageHelper";
import formNavigationUtils from "utils/formNavigationUtils";
/**
 *
 */
const AccountDefaultSettings = () => {
	const [acctSettings, setAcctSettings] = useState({});

	//const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");

	// Hooks for Account Setting store values
	const [daysInYear, setDaysInYear] = useState(null);
	const [firstReceiptNo, setFirstReceiptNo] = useState(null);
	const [gracePeriod, setGracePeriod] = useState(null);
	const [latePercent, setLatePercent] = useState(null);
	const [lateFixAmount, setLateFixAmount] = useState(null);
	const [payoffMethod, setPayoffMethod] = useState(null);
	const [autoLateCalc, setAutoLateCalc] = useState(false);
	const [daysLateType, setDaysLateType] = useState(null);
	const [lateType, setLateType] = useState(null);
	const [graceDaysForPmtDate, setGraceDaysForPmtDate] = useState(false);

	useEffect(() => {
		getData();
		// eslint-disable-next-line
	}, []);

	const save = () => {
		acctSettings.daysInYear = daysInYear;
		acctSettings.firstReceiptNo = firstReceiptNo;
		acctSettings.gracePeriod = gracePeriod;
		acctSettings.latePercent = latePercent;
		acctSettings.lateFixAmount = lateFixAmount;
		acctSettings.payoffMethod = payoffMethod;
		acctSettings.autoLateCalc = autoLateCalc;
		acctSettings.daysLateType = daysLateType;
		acctSettings.lateType = lateType;
		acctSettings.graceDaysForPmtDate = graceDaysForPmtDate;
		saveAcctDefaults(acctSettings).then(
			(response) => {
				setAcctSettings(acctSettings);
				saveSuccessAlert();
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, saveFailAlert);
				}
			}
		);
	};

	const getData = () => {
		getAcctDefaults(locationID).then(
			(res) => {
				setAcctSettings(res.data.content);
				setDaysInYear(res.data.content.daysInYear);
				setFirstReceiptNo(res.data.content.firstReceiptNo);
				setGracePeriod(res.data.content.gracePeriod);
				setLatePercent(res.data.content.latePercent);
				setLateFixAmount(res.data.content.lateFixAmount);
				setPayoffMethod(res.data.content.payoffMethod);
				setAutoLateCalc(res.data.content.autoLateCalc);
				setDaysLateType(res.data.content.daysLateType);
				setLateType(res.data.content.lateType);
				setGraceDaysForPmtDate(res.data.content.graceDaysForPmtDate);
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
				}
			}
		);
	};

	return (
		<div onKeyDown={formNavigationUtils}>
			<Form>
				<Card>
					<CardHeader>
						<CardTitle tag="h3">Account setting</CardTitle>
					</CardHeader>
					<CardBody>
						<Col>
							<Row className="mx-0 pb-2">
								<IntegerInput
									disableValidation
									value={daysInYear}
									onChange={(e) => setDaysInYear(e)}
									name="daysInYear"
									label="Days In Year"
									type="number"
									colSize="col-sm-3"
								/>
								<InputElement
									disableValidation
									colSize="col-sm-3"
									value={firstReceiptNo}
									onChange={(e) => setFirstReceiptNo(e.target.value)}
									name="firstReceiptNo"
									label="First ReceiptNo"
									type="text"
								/>
								<SelectorElement
									name="payoffMethod"
									label="Payoff Method"
									colSize="col-sm-4"
									value={payoffMethod}
									onChange={(e) => setPayoffMethod(e.target.value)}
									options={[
										{ value: 0, label: "RULE_OF_78" },
										{ value: 1, label: "SIMPLE_INT" },
										{ value: 2, label: "SCHEDULE_INT" },
									]}
									disableValidation
								/>
							</Row>
							<Row className="mx-0 pb-2">
								<SelectorElement
									name="lateType"
									label="Late Charge by"
									colSize="col-sm-3"
									value={lateType}
									onChange={(e) => setLateType(e.target.value)}
									options={[
										{ value: 0, label: "Pct of Pmt" },
										{ value: 2, label: "Pct of Past Due" },
										{ value: 1, label: "Flat Fee" },
									]}
									disableValidation
								/>
								<PercentInput
									label="Late Percentage"
									value={latePercent}
									onChange={setLatePercent}
									disableValidation
									colSize="col-sm-3"
								/>
								<Label> + </Label>
								<CurrencyInput
									value={lateFixAmount}
									onChange={setLateFixAmount}
									name="lateFixAmount"
									label="Fixed Amount"
									type="Currency"
									colSize="col-sm-3"
									disableValidation
								/>
							</Row>
							<Row className="mx-0 pb-2">
								<IntegerInput
									disableValidation
									value={gracePeriod}
									onChange={(e) => setGracePeriod(e)}
									name="gracePeriod"
									label="Grace Period(Days))"
									type="number"
									colSize="col-sm-3"
								/>
								<SelectorElement
									name="daysLateType"
									label="Days Late Type"
									colSize="col-sm-6"
									value={daysLateType}
									onChange={(e) => setDaysLateType(e.target.value)}
									options={[
										{ value: 0, label: "Last  Payment (Previous Pmt. Date)" },
										{ value: 1, label: "Last Current (Scheduled Date)" },
										{ value: 2, label: "Last Amort Paid (Paid Through Date)" },
									]}
									disableValidation
								/>
							</Row>
							<Row className="mx-0 pb-2">
								<Col className="text-center">
									<CheckBoxElement
										disableValidation
										checked={autoLateCalc}
										onChange={() => {
											setAutoLateCalc(!autoLateCalc);
										}}
										name={autoLateCalc}
										label="Auto Calculate Late Charges"
										//colSize="col-sm-3"
										labelClass="pl-4 "
									/>
								</Col>
								<Col className="text-center">
									<CheckBoxElement
										disableValidation
										checked={graceDaysForPmtDate}
										onChange={() => {
											setGraceDaysForPmtDate(!graceDaysForPmtDate);
										}}
										name={graceDaysForPmtDate}
										label="Grace Days For Payment Date"
										//colSize="col-sm-3"
										labelClass="pl-4"
									/>
								</Col>
								<Button
									color="primary"
									onClick={() => {
										save();
									}}
									size="md"
								>
									<i className="nc-icon nc-cloud-download-93"></i> Save Default
								</Button>
							</Row>
						</Col>
					</CardBody>
				</Card>
			</Form>
		</div>
	);
};

export default AccountDefaultSettings;

import React, { useState } from "react";
import ChildrenModal from "../components/modals/ChildrenModal";
import shallow from "zustand/shallow";
import useCurrentAccountStore from "./AccountStore";
import AccountPaymentsTable from "./AccountPaymentsTable";
import AccountPaymentForm from "./AccountPaymentForm";
import NavBar from "components/tabs/TabsHolder";

const AccountPayments = () => {
	const tabs = ["Detailed ", "Key Info"];
	const { payments } = useCurrentAccountStore(
		(state) => ({
			payments: state.payments,
		}),
		shallow
	);
	const [currentIndex, setCurrentIndex] = useState(payments?.length);
	const [open, setOpen] = useState(false);

	const toggleOpen = () => setOpen(!open);
	const tabComponents = [
		<AccountPaymentsTable
			isShortList={false}
			setCurrentIndex={setCurrentIndex}
			toggle={toggleOpen}
		/>,
		<AccountPaymentsTable
			isShortList={true}
			setCurrentIndex={setCurrentIndex}
			toggle={toggleOpen}
		/>,
	];
	return (
		<>
			<ChildrenModal
				modal={open}
				toggle={toggleOpen}
				size="md"
				children={
					<AccountPaymentForm
						currentIndex={currentIndex}
						setCurrentIndex={setCurrentIndex}
						toggle={toggleOpen}
					/>
				}
			/>
			<NavBar
				navClass="nav-pills-secondary justify-content-center nav nav-pills"
				tabs={tabs}
				tabComponents={tabComponents}
			/>
		</>
	);
};

export default AccountPayments;

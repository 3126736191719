import React from "react";
import useCurrentAccountStore from "./AccountStore";
import AccountHook from "./AccountHook";
import shallow from "zustand/shallow";
import { useHistory } from "react-router-dom";
import { PATHS } from "../constants/Constants";
import { useFormContext } from "react-hook-form";
import Swal from "sweetalert2";

export const AccountSaveButton = ({ toggle = false }) => {
	const history = useHistory();
	const { account } = useCurrentAccountStore(
		(state) => ({
			account: state.account,
		}),
		shallow
	);
	const save = AccountHook();
	//console.log(account);
	const saveData = () => {
		if (account.buyerRecNum === null) {
			Swal.fire(" Please select a customer first", "error");
			return;
		}

		save();
		if (account.id != null) {
			history.push(PATHS.ACCT_DETAIL, {
				acctID: res.account.account.id,
			});
		}
	};
	const methods = useFormContext();
	return (
		<>
			<div
				className="btn btn-success float-button"
				onClick={methods.handleSubmit(saveData, (err) => {
					console.log(err);
				})}
				//				onClick={saveData}
			>
				<i className="nc-icon nc-check-2"></i>&nbsp;Generate Account
			</div>
		</>
	);
};

export default AccountSaveButton;

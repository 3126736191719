import React, { useEffect, useState } from "react";
import {
	Card,
	Row,
	CardTitle,
	Col,
	CardHeader,
	Form,
	Label,
	CardBody,
	Button,
	CardText,
	CardFooter,
	FormGroup,
	Input,
} from "reactstrap";
import shallow from "zustand/shallow";
import dayjs from "dayjs";
import NumberFormat from "react-number-format";

import useCurrentSaleStore from "stores/SaleStore";
import { DealStatus, PATHS } from "constants/Constants";
import useCurrentDealStore from "stores/DealStore";
import formNavigationUtils from "utils/formNavigationUtils";
import LabelAndInput from "features/deal/subFeatures/sale/LabelAndInput";
import InsuranceAndGAP from "features/deal/subFeatures/sale/saleComponents/InsuranceAndGAP";
import ServiceContract from "features/deal/subFeatures/sale/saleComponents/ServiceContract";
import Aftermarket from "features/deal/subFeatures/sale/saleComponents/Aftermarket";
import DownPayment from "features/deal/subFeatures/sale/saleComponents/DownPayment";
import Taxes from "features/deal/subFeatures/sale/saleComponents/Taxes";
import DMVFee from "features/deal/subFeatures/sale/saleComponents/DMVFee";
import SellerFees from "features/deal/subFeatures/sale/saleComponents/SellerFees";
import { useFormContext } from "react-hook-form";
import ChildrenModal from "components/modals/ChildrenModal";
import { showApiError } from "utils/errorRoutingUtils";
import { retrieveFailAlert } from "utils/alertUtils";
import { getSettings } from "api/SettingsAPI";
import PrepaidFinanceCharge from "features/deal/subFeatures/finance/PrepaidFinanceCharge";
import AdjustmentAPR from "features/deal/subFeatures/finance/AdjustmentAPR";
import { useFromUser } from "stores/LocalStorageHelper";
import useCurrentRecapStore from "stores/RecapStore";
import FinanceDaysToPayInput from "features/deal/subFeatures/finance/FinanceDaysToPayInput";
import FinanceAPRInput from "features/deal/subFeatures/finance/FinanceAPRInput";
import FinanceTermInput from "features/deal/subFeatures/finance/FinanceTermInput";
import InventoryListModal from "../inventory/InventoryListModal";
import TaxCountySelector from "features/deal/subFeatures/taxCountySelector/TaxCountySelector";

import {
	//createFileAndDownload,
	displayError,
	displayPdf,
	formLoading,
	getQuickQuote,
} from "api/FormsAPI";
import { getBuildDealTemplate, getDealTemplatesNew } from "api/DealAPI";
//import DealTemplateList from "features/deal/subFeatures/settings/subFeatures/settingsTabs/dealTemplates/DealTemplateList";
import useCurrentSettingStore from "stores/SettingStore";
import Swal from "sweetalert2";

import PaymentOptionsModal from "../paymentOptions/PaymentOptionsModal";
import { ZipInputElement } from "features/forms/components/InputElement";
import useBuildDeal from "features/deal/hooks/useBuildDeal";
import useSetDeal from "features/deal/hooks/useSetDeal";
import useCalculateSales from "features/deal/hooks/useCalculateSales";
import NumberSelector from "features/inventory/subFeatures/selector/NumberSelector";
import OpenModalButton from "features/deal/components/OpenModalButton";
import ContractInfo from "../dealDetails/ContractInfoDetail";
import ZipCityTable from "components/tables/ZipCityTable";
import { buildNewDeal } from "api/DealAPI";
import Loading from "../../../../components/loadingSpinner/Loading";
import { useHistory } from "react-router-dom";
import useRoute from "../../../../hooks/useRoute";

export const QuickQuoteDeal = () => {
	const dealDetailPage = useRoute(PATHS.DEAL_DETAIL);
	const today = dayjs().format("YYYY-MM-DD");
	const dealNum = dayjs().format("MMDDYY-HHmmss");
	const history = useHistory();
	const { taxDefaults, commissionSettings, profitSettings, setSettingFields } =
		useCurrentSettingStore((state) => state, shallow);
	const [templates, setTemplates] = useState([]);
	const [selected, setSelected] = useState(-1); // The current selected template index from the template list
	const [zip, setZip] = useState("");
	const [loading, setLoading] = useState(true);
	const [cityModal, setCityModal] = useState(false);
	const [addressData, setAddressData] = useState({});

	// Toggles the modal
	const toggleCityModal = () => setCityModal(!cityModal);

	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	const userID = useFromUser("ID");
	const dcLoginID = useFromUser("ID");

	console.log(userID);

	const methods = useFormContext();
	//recap Store
	const { resetRecap } = useCurrentRecapStore(
		(state) => ({
			resetRecap: state.reset,
		}),
		shallow
	);

	// Sale store
	const {
		resetSale,
		editDealNumber,
		editLoanDate,
		editFirstDueDate,
		editFinalDueDate,
		editTaxRate,
		editBankFee,
		daysToPay,
		editDaysToPay,
		firstDueDate,
		loanDate,
	} = useCurrentSaleStore(
		(state) => ({
			resetSale: state.reset,
			editDealNumber: state.editDealNumber,
			editLoanDate: state.editLoanDate,
			editFirstDueDate: state.editFirstDueDate,
			editFinalDueDate: state.editFinalDueDate,
			editTaxRate: state.editTaxRate,
			editBankFee: state.editBankFee,
			daysToPay: state.daysToPay,
			editDaysToPay: state.editDaysToPay,
			firstDueDate: state.firstDueDate,
			loanDate: state.loanDate,
		}),
		shallow
	);
	// Deal store,
	const { resetDeal, currentAddress, editCurrentAddress, type, dealId } =
		useCurrentDealStore(
			(state) => ({
				resetDeal: state.reset,
				currentAddress: state?.buyerAddress || {},
				editCurrentAddress: state.editBuyerAddress,
				type: state.deal.type,
				dealId: state.deal.ID,
			}),
			shallow
		);

	const {
		editDealSettings,
		vehicle,
		editIsLoading,
		editSalesmanRecNum,
		editSalesPeople,
		editLenders,
		editTradeVendors,
		editWarrantyVendors,
		editSellerFeeTypes,
		editDmvFeeTypes,
		editGapCompanies,
		editCredit700Lenders,
		editCountyRecNum,
		editTrades,
	} = useCurrentDealStore((state) => state, shallow);

	const getDeal = useBuildDeal();
	const setDeal = useSetDeal();

	const handleCurrentZipChange = (zip) => {
		setAddressData({
			zip,
			address: {
				county: currentAddress.county,
				city: currentAddress.city,
				state: currentAddress.state,
				zip,
			},
		});
		toggleCityModal();
	};

	// TODO link to special endpoint that generates form from DealObj in request body
	const handlePrint = () => {
		formLoading("PDF");
		const deal = getDeal();
		console.log(deal);
		getQuickQuote(deal).then(
			(response) => {
				displayPdf(response);
				Swal.close();
			},
			(err) => {
				displayError(err);
			}
		);
	};
	const handleMakeDeal = () => history.push(dealDetailPage);

	/**
	 * Initializes a new deal with the provided response data.
	 *
	 * @param {Array} res - Array containing response data for the new deal
	 *
	 * This function has been refactored to address potential issues with object extensibility
	 * and immutability. Key changes and reasons:
	 *
	 * 1. Deep cloning of settingsRes:
	 *    We use _.cloneDeep() to create a complete copy of the settings object.
	 *    This ensures we're not modifying the original object, which could be frozen
	 *    or non-extensible, leading to errors like "Cannot add property taxRegion,
	 *    object is not extensible".
	 *
	 * 2. Safe initialization of taxDefaults:
	 *    We check if taxDefaults exists before trying to add properties to it.
	 *    This prevents errors that could occur if the object structure is unexpected.
	 *
	 * 3. Direct assignment of taxRegion:
	 *    Instead of checking for extensibility, we directly assign the taxRegion property.
	 *    This is safe because we're working with a cloned object.
	 *
	 * 4. Removed extensibility checks:
	 *    With the deep clone approach, explicit extensibility checks are no longer necessary.
	 *
	 * These changes ensure that we're always working with mutable objects and prevent
	 * issues that can arise from modifying potentially immutable structures received from APIs
	 * or state management systems. This approach is more robust and less error-prone,
	 * especially when dealing with nested object structures whose shape or immutability
	 * we might not always be certain of.
	 */
	const initializeNewDeal = (res) => {
		console.log("zzz", res[0].data.content);
		console.log(res[1].data.content);
		console.log(res[2].data.content);

		let settingsRes = _.cloneDeep(res[1].data.content);
		const newDealRes = res[2].data.content;

		editSalesPeople(newDealRes.salesPeople);
		editLenders(newDealRes.lenders);
		editTradeVendors(newDealRes.tradeVendors);
		editWarrantyVendors(newDealRes.warrantyVendors);
		editSellerFeeTypes(newDealRes.sellerFeeTypes);
		editDmvFeeTypes(newDealRes.dmvFeeTypes);
		editGapCompanies(newDealRes.gapCompanies);
		editCredit700Lenders(newDealRes.credit700Lenders);

		// Ensure taxDefaults exists and add taxRegion
		if (!settingsRes.taxDefaults) {
			settingsRes.taxDefaults = {};
		}
		settingsRes.taxDefaults.taxRegion = 0;

		console.log(
			"Is settingsRes.taxDefaults extensible?",
			Object.isExtensible(settingsRes.taxDefaults)
		);

		editDealSettings(settingsRes);
		editDealNumber(dealNum);
		editLoanDate(today);
		editFirstDueDate(today);
		editFinalDueDate(today);
		editIsLoading(false);
		editSalesmanRecNum(dcLoginID);
	};

	const setTemplate = (template) => {
		Promise.all([
			//getSettings(dealerID, locationID),
			getBuildDealTemplate(template.dealID),
		]).then(
			(res) => {
				console.log(res[0].data.content);
				//console.log(res[1].data.content);
				const newDeal = res[0].data.content;
				// doing this to conform to setDeal rules
				let deal = {};
				let data = {};
				let content = {};

				deal.data = data;
				deal.data.content = content;
				deal.data.content = newDeal;
				deal.data.content.buyerAddress = currentAddress;
				deal.data.content.vehicle = vehicle;
				deal.data.content.deal.sale.loanDate = today;
				deal.data.content.deal.sale.firstDueDate = today;
				deal.data.content.deal.salesPersonRecnum = userID;
				deal.data.content.deal.dealSettings = JSON.stringify({
					taxDefaults,
					commissionSettings,
					profitSettings,
				});
				setDeal(deal.data.content);
				editCountyRecNum(taxDefaults.countyRecNum);
				editTaxRate(taxDefaults.taxRate);
				editBankFee(0);
				let tempTrade = { allowance: 0, payoff: 0 };
				editTrades([tempTrade]);
				setLoading(false);
			},
			(err) => {
				console.log(err);
				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
				}
				setLoading(false);
			}
		);
	};
	// Set the store values to the selected template, else empty
	useEffect(() => {
		console.log(selected);
		if (Number(selected) !== -1) {
			setLoading(true);
			setTemplate(
				templates.find((template) => template.dealID === Number(selected))
			);
		} else {
			resetDeal();
			resetSale();
			resetRecap();
		}
		// eslint-disable-next-line
	}, [selected]);

	useEffect(() => {
		resetDeal();
		resetSale();
		resetRecap();

		Promise.all([
			getDealTemplatesNew(dealerID, locationID),
			getSettings(dealerID, locationID),
			buildNewDeal(locationID),
		]).then(
			(res) => {
				const data = res[0].data.content;
				setSettingFields(res[1].data.content);
				editDealSettings(res[1].data.content);
				setTemplates(data);
				if (data.length === 0) {
					initializeNewDeal(res);
				} else if (data.length >= 1) {
					setTemplate(data[0]);
					setSelected(data[0].dealID);
				}
			},
			(err) => {
				if (!err.isGeneralError) {
					showApiError(err, retrieveFailAlert);
				}
			}
		);

		return () => {
			console.log("resetting");
			resetDeal();
			resetSale();
			resetRecap();
		};
		// eslint-disable-next-line
	}, []);

	return (
		<>
			<div className="pt-3 pb-3 mobileHeading">
				<Col className="text-center">
					<CardTitle>
						{" "}
						<h2>Deal Quote</h2>
					</CardTitle>
				</Col>
			</div>
			<Row>
				<div className="col-lg-4">
					<DealTemplateList
						templates={templates}
						selected={selected}
						setSelected={setSelected}
					/>
				</div>
				<div className="col-lg-4">
					<ZipInputElement
						{...methods}
						value={zip}
						onChange={setZip}
						openZipModal={handleCurrentZipChange}
						name="currentZip"
						label="ZipCode"
						disableValidation
						wholeRow
					/>
					<Label>
						{currentAddress.zip !== undefined &&
							currentAddress.city +
								" " +
								currentAddress.state +
								" " +
								currentAddress.zip}
					</Label>
				</div>

				<div className="col-lg-4">
					<Label>
						Stock
						<Input value={vehicle?.stockNo} />
						{vehicle?.year !== undefined &&
							vehicle?.year + " " + vehicle?.make + " " + vehicle?.model}
						<InventoryListModal modalTitle="Select Contact" />
					</Label>
				</div>

				<ChildrenModal
					modal={cityModal}
					toggle={toggleCityModal}
					modalTitle="Cities"
				>
					<ZipCityTable
						data={addressData}
						toggle={toggleCityModal}
						address={currentAddress}
						setAddress={editCurrentAddress}
						isDeal={true}
						isBuyer={true}
					/>
				</ChildrenModal>
			</Row>
			{loading && <Loading />}
			{!loading && (
				<Row>
					<div className="col-md-4">
						<Card>
							<Sale />
						</Card>
					</div>
					<div className="col-md-4">
						<Card>
							<TermsTable />
						</Card>
					</div>
					<div className="col-md-4">
						<Card>
							<Summary />
							<CardFooter>
								<Row>
									<Col>
										<Button
											onClick={() => {
												handlePrint();
											}}
										>
											Print
										</Button>
									</Col>
									<Col>
										<Button
											color="success"
											onClick={() => {
												editTrades([]);
												handleMakeDeal();
											}}
										>
											Make a Deal
										</Button>
									</Col>
								</Row>
							</CardFooter>
						</Card>
					</div>
				</Row>
			)}
		</>
	);
};
export default QuickQuoteDeal;

const Sale = () => {
	const {
		price,
		trade,
		payoff,
		manufacturerRebate,
		editPrice,
		editManufacturerRebate,
	} = useCurrentSaleStore(
		(state) => ({
			price: state.price,
			manufacturerRebate: state.manufacturerRebate,
			editPrice: state.editPrice,
			editManufacturerRebate: state.editManufacturerRebate,
			trade: state.trade,
		}),
		shallow
	);

	const { type, calculate, editValueInTrades } = useCurrentDealStore(
		(state) => ({
			type: state.deal.type,
			// taxRegion: state?.deal?.dealSettings?.taxDefaults?.taxRegion,
			calculate: state.calculate,
			editValueInTrades: state.editValueInTrades,
		}),
		shallow
	);

	const handleTrade = (val) => {
		editValueInTrades(0, "allowance", val);
		calculate();
	};

	const handlePayoff = (val) => {
		editValueInTrades(0, "payoff", val);
		calculate();
	};

	useCalculateSales();

	return (
		<div>
			<CardHeader>
				<CardTitle tag="h3">Quote Deal</CardTitle>
			</CardHeader>
			<div className="card-body pb-3" onKeyDown={formNavigationUtils}>
				<Form className="deal-sale">
					<Row>
						<div className="col-xl-6 pl-2 pr-0">
							<LabelAndInput
								name="dealPrice"
								value={price}
								label="Price"
								onChange={editPrice}
								btnClass="w-100 btn-md btn-primary  btn"
								buttonLabel="true"
							/>
						</div>
						<div className="col-xl-6 pl-2 pr-0">
							<DMVFee />
						</div>
						<div className="col-xl-6 pl-2 pr-0">
							<SellerFees />
						</div>
						<div className="col-xl-6 pl-2 pr-0">
							<InsuranceAndGAP />
						</div>
						<div className="col-xl-6 pl-2 pr-0">
							<ServiceContract />
						</div>
						<div className="col-xl-6 pl-2 pr-0">
							<Aftermarket />
						</div>
						<div className="col-xl-6 pl-2 pr-0">
							<ContractInfo />
						</div>
						<div className="col-xl-6 pl-2 pr-0">
							<LabelAndInput
								name="dealMFR"
								className="text-danger bold"
								disabled={type !== DealStatus.PENDING}
								buttonLabel={true}
								value={manufacturerRebate}
								label="MFR Rebate"
								onChange={editManufacturerRebate}
								sign="-"
								btnClass="w-100 btn-md btn-primary btn px-0"
							/>
						</div>
						<div className="col-xl-6 pl-2 pr-0">
							<LabelAndInput
								name="trade"
								className="text-danger bold"
								buttonLabel={true}
								value={trade}
								label="Trade-In"
								onChange={handleTrade}
								sign="-"
								btnClass="w-100 btn-md btn-primary btn px-0"
							/>
						</div>
						<div className="col-xl-6 pl-2 pr-0">
							<LabelAndInput
								name="payoff"
								className="text-primary bold"
								buttonLabel={true}
								value={payoff}
								label="Payoff"
								onChange={handlePayoff}
								sign="+"
								btnClass="w-100 btn-md btn-primary btn px-0"
							/>
						</div>
						<div className="col-xl-6 pl-2 pr-0">
							<DownPayment />
						</div>
						{/* <div className="col-xl-6 pl-2 pr-0">
							<DeferredPickup />
						</div> */}
						<div className="col-xl-6 pl-2 pr-0">
							<Taxes />
						</div>
						<div className="col-12 pl-2 pr-0">
							<hr className="my-2" />
							<TaxCountySelector />
						</div>
					</Row>
				</Form>
			</div>
		</div>
	);
};
const TermsTable = () => {
	const [showProfit, setShowProfit] = useState(false);

	const toggle = () => {
		setShowProfit(!showProfit);
	};

	const {
		loanPeriod,
		editLoanPeriod,
		bankFee,
		daysToPay,
		editDaysToPay,
		firstDueDate,
		loanDate,
	} = useCurrentSaleStore(
		(state) => ({
			loanPeriod: state.loanPeriod,
			editLoanPeriod: state.editLoanPeriod,
			bankFee: state.bankFee,
			payoff: state.payoff,
			daysToPay: state.daysToPay,
			editDaysToPay: state.editDaysToPay,
			firstDueDate: state.firstDueDate,
			loanDate: state.loanDate,
		}),
		shallow
	);
	//recap Store
	const { frontendProfit, backendProfit } = useCurrentRecapStore(
		(state) => ({
			frontendProfit: state.frontendProfit,
			backendProfit: state.backendProfit,
		}),
		shallow
	);

	// Deal store
	const { type, dealId } = useCurrentDealStore(
		(state) => ({
			type: state.deal.type,
			dealId: state.deal.ID,
		}),
		shallow
	);
	//toggle payment option
	const [paymentOptions, setPaymentOptions] = useState(false);
	const togglePayment = () => setPaymentOptions(!paymentOptions);

	return (
		<div>
			<CardHeader>
				<CardTitle tag="h3">Payments</CardTitle>
			</CardHeader>
			<CardBody className="pb-0" onKeyDown={formNavigationUtils}>
				<Form className="deal-terms-table">
					<Row className="mx-0">
						<Col xl="5" className="mb-2">
							<Label className="">Period</Label>
							<NumberSelector
								name="dealLoanPeriod"
								readOnly={type !== DealStatus.PENDING}
								value={loanPeriod}
								selectedOption={loanPeriod}
								handleChange={(e) => {
									editLoanPeriod(e);
								}}
								selectType={"loanPeriod"}
								default={true}
							/>
						</Col>
						<Col xl="3" className="mb-2">
							<Label value="">Term</Label>
							<FinanceTermInput />
						</Col>
						<Col xl="3" className="mb-2">
							<FinanceAPRInput />
						</Col>
						<Col xl="5" className="mb-2">
							<Label value="">Days to 1st Pmt</Label>
							<FinanceDaysToPayInput
								loanPeriod={loanPeriod}
								daysToPay={daysToPay}
								editDaysToPay={editDaysToPay}
								firstDueDate={firstDueDate}
								loanDate={loanDate}
								type={type}
								dealId={dealId}
							/>
						</Col>
					</Row>

					{bankFee > 0 && (
						<Row>
							<Col xl="4" className="mb-2">
								<Label value="">Adj </Label>
								<AdjustmentAPR />
							</Col>
							<Col xl="6" className="mb-2">
								<Label value="">Prepaid Finance Charge</Label>
								<PrepaidFinanceCharge />
							</Col>
						</Row>
					)}

					<hr className="my-2" />
					<Col>
						<Row className="mb-3">
							<div className={!showProfit ? "mx-auto" : "row"}>
								<Button className="px-4 " color="primary" onClick={toggle}>
									QP
								</Button>
								{showProfit && (
									<CardText
										className={
											frontendProfit + backendProfit > 0
												? "text-default px-4 pt-2"
												: "text-danger px-4 pt-2"
										}
										tag="h5"
									>
										<NumberFormat
											value={frontendProfit + backendProfit}
											thousandSeparator={true}
											decimalScale={2}
											fixedDecimalScale={true}
											prefix={"$"}
											isNumericString={true}
											displayType="text"
										/>
									</CardText>
								)}
								<OpenModalButton
									buttonText="Payment Options"
									onClick={togglePayment}
									iconClass="nc-icon nc-refresh-69"
									className="btn btn-lg btn-link"
								/>
								<ChildrenModal
									modalTitle="Payment Options"
									modal={paymentOptions}
									toggle={togglePayment}
									children={<PaymentOptionsModal toggle={togglePayment} />}
									size="lg"
								/>
							</div>
						</Row>
					</Col>
				</Form>
				<CardFooter />
			</CardBody>
		</div>
	);
};

const Summary = () => {
	const {
		firstDueDate,
		finalDueDate,
		payment,
		// finalPmt,
		term,
		amtFinanced,
		financeCharge,
		loan,
		balloonDate,
		balloonTerm,
		balloon,
		hasBalloon,
		// onePay,
	} = useCurrentSaleStore((state) => state, shallow);
	console.log(financeCharge);
	const CurrencyDisplay = ({ value, className = "bold" }) => (
		<NumberFormat
			readOnly={true}
			value={value}
			thousandSeparator={true}
			decimalScale={2}
			fixedDecimalScale={true}
			prefix={"$"}
			isNumericString={true}
			customInput={Input}
			className={className}
		/>
	);

	const summaryRow1 = [
		{
			label: "First Due Date",
			value: (
				<Input
					value={firstDueDate ? dayjs(firstDueDate).format("MM/DD/YYYY") : ""}
					disabled
				/>
			),
		},
		{
			label: "Final Due Date",
			value: (
				<Input
					value={finalDueDate ? dayjs(finalDueDate).format("MM/DD/YYYY") : ""}
					disabled
				/>
			),
		},
		{
			label: (term > 1 ? term : 1) + " Payments of",
			value: (
				<CurrencyDisplay value={payment} className="regular-payment bold" />
			),
		},
	];

	const balloonSummaryRow = [
		{
			label: "First Due Date",
			value: (
				<Input
					value={firstDueDate ? dayjs(firstDueDate).format("MM/DD/YYYY") : ""}
					disabled
				/>
			),
		},
		{
			label: "Balloon Date",
			value: (
				<Input
					value={balloonDate ? dayjs(balloonDate).format("MM/DD/YYYY") : ""}
					disabled
				/>
			),
		},
		{
			label: (balloonTerm >= 1 ? balloonTerm : 0) + " Payments",
			value: <CurrencyDisplay value={payment} className="text-success bold" />,
		},
		{
			label: "Balloon Payment",
			value: <CurrencyDisplay value={balloon} />,
		},
	];

	const summaryRow2 = [
		// {
		// 	label: "Out the Door Price",
		// 	value: <CurrencyDisplay value={onePay} />,
		// },
		{
			label: "Amount Financed",
			value: <CurrencyDisplay value={amtFinanced} />,
		},
		{
			label: "Finance Charge",
			value: <CurrencyDisplay value={financeCharge} />,
		},
	];

	const summaryRow3 = [
		{
			label: "Total of Payments",
			value: <CurrencyDisplay value={loan} />,
		},
	];

	let row1 = summaryRow1;

	if (hasBalloon) {
		row1 = balloonSummaryRow;
	}

	return (
		<div className="deal-summary">
			<CardHeader>
				<CardTitle tag="h3">Deal Summary</CardTitle>
			</CardHeader>
			<CardBody className="pb-3">
				<Row>
					{row1.map((obj) => (
						<>
							<Col className="mb-2 col-6 col-xxl-6">
								<Label value="">{obj.label}</Label>
								{obj.value}
							</Col>
						</>
					))}
				</Row>
				<hr className="my-2" />
				{summaryRow2.map((obj) => (
					<div>
						<Row className="">
							<Col xl="6">
								<Label className="pt-2">{obj.label}</Label>
							</Col>
							<Col xl="6">{obj.value}</Col>
						</Row>
					</div>
				))}

				<hr className="my-2" />
				{summaryRow3.map((obj) => (
					<Row>
						<Col className="my-2 col-6 px-0 text-center">
							<h6>{obj.label}</h6>
						</Col>
						<Col className="my-2 col-6 px-0 text-center deal-total">
							{obj.value}
						</Col>
					</Row>
				))}
			</CardBody>
		</div>
	);
};

const DealTemplateList = ({ templates, selected, setSelected }) => {
	const options = templates.map((obj) => (
		<option key={obj.ID} value={obj.dealID}>
			{obj.name}
		</option>
	));

	return (
		<div className="d-flex justify-content-center mt-2 mb-5">
			<FormGroup className="col">
				<Label>Deal Template</Label>
				<Input
					onChange={(e) => setSelected(Number(e.target.value))}
					type="select"
					value={selected}
				>
					{options != null && options.length > 0 && options}
				</Input>
			</FormGroup>
		</div>
	);
};

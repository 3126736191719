import { userBaseUrl } from "../api/Constants";
import createAxiosInstance from "../utils/apiUtils";
//import { LOCAL } from "../api/Constants";

//console.log(LOCAL);
-console.log(userBaseUrl);
const instance = createAxiosInstance({
	baseURL: userBaseUrl + "/bhph/",
	//baseURL: LOCAL,
});
// const localInstance = createAxiosInstance({
// 	baseURL: LOCAL,
// });

// Retrieve  a New Account
export const getNewAccount = async () => {
	//return await instance.get(`newAcct`);
	return await instance.get(`newAcct`);
};

//Get a list of all the Accounts for a by a AccounterID and locationID
export const getAccountList = async (accounterID, locationID) => {
	return await instance.get(`list/${accounterID}/${locationID}`);
};

// Get number of the Accounts by Status
export const getAccountStatusList = async (locationID) => {
	return await instance.get(`numAcctByStatus/${locationID}`);
};

// Get a list of the Accounts by Status
export const getAccountListByStatus = async (dealerID, locationID, status) => {
	return await instance.get(`list/${dealerID}/${locationID}/${status}`);
};

// Get a list of customers needed for Account header
export const getAccountCustomerList = async (dealerID, locationID) => {
	return await instance.get(`header/customer/list/${dealerID}/${locationID}`);
};

// Saves a Account
export const saveAccount = async (body) => {
	console.log(body);

	return await instance.post(`save`, body);
};

// Saves a saveAccountTable
export const saveAccountTable = async (body) => {
	console.log(body);

	return await instance.post(`saveAccountTable`, body);
};

// Delete a Account
export const deleteAccount = async (accountID) => {
	return await instance.get(`delete/${accountID}`);
};

// Retrieves information about a Account
export const getAccountDetails = async (accountID) => {
	return await instance.get(`${accountID}`);
};
// Retrieves information about a Account by AccountNumber
export const getAccountByAcctNumber = async (locationID, accountNumber) => {
	return await instance.get(`acctNum/${locationID}/${accountNumber}`);
};
// Retrieves  Account Defaults
export const getAcctDefaults = async (locationID) => {
	return await instance.get(`default/${locationID}`);
};

// Save  Account Defaults
export const saveAcctDefaults = async (body) => {
	console.log(body);

	return await instance.post(`saveAccountDefaults`, body);
};

export const reCalculateAccount = async (accountID) => {
	console.log(accountID);
	return await instance.get(`reCalculate/${accountID}`);
};

// Transfer Deal to BHPH
export const transferDealToBHPH = async (dealID) => {
	return await instance.get(`transferToBHPH/${dealID}`);
};
export const deleteAdjustment = async (adjID) => {
	return await instance.get(`deleteAdj/${adjID}`);
};
export const deleteLateFee = async (lateID) => {
	return await instance.get(`deleteLateFee/${lateID}`);
};
export const deletePayment = async (paymentID) => {
	return await instance.get(`payments/delete/${paymentID}`);
};

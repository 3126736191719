import React from "react";
import { Button } from "reactstrap";

import { useFromUser } from "../stores/LocalStorageHelper";
import { displayError, displayPdf, getPdf } from "../api/FormsAPI";
import Swal from "sweetalert2";

const ReceiptButton = ({ paymentId, accountID }) => {
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	const username = useFromUser("lastName") + " " + useFromUser("firstName");

	const handlePrint = () => {
		const body = {
			dcReportID: 512,
			params: { dealerID, locationID, paymentID: paymentId, cashier: username },
		};

		getPdf(body).then(
			(response) => {
				displayPdf(response);
				Swal.close();
			},
			(err) => {
				displayError(err);
			}
		);
	};

	return (
		<Button
			onClick={() => {
				handlePrint();
			}}
		>
			Print
		</Button>
	);
};
export default ReceiptButton;

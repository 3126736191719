import React, { useState, useEffect } from "react";
import { Button, Form } from "reactstrap";
import InputElement, {
	CurrencyInputElement,
	IntegerInput,
} from "features/forms/components/InputElement";
import InputWrapper from "features/forms/components/InputWrapper";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import { setLocale } from "yup";
import { useForm, FormProvider } from "react-hook-form";
import { useFromUser } from "../stores/LocalStorageHelper";
import dayjs from "dayjs";

/**
 * PickupForm component to handle adding and editing Pickups.
 *
 * @param {Object} props - The component props.
 * @param {number} props.currentIndex - The current index of the down payment being edited.
 * @param {function} props.setCurrentIndex - Function to set the current index.
 * @param {function} props.toggle - Function to toggle the form visibility.
 */
const AccountAddDeferredForm = ({
	acctObj,
	currentIndex,
	setCurrentIndex,
	toggle,
}) => {
	const dealerID = useFromUser("dealerID");
	const locationID = useFromUser("locationID");
	const userID = useFromUser("ID");

	const [dateDue, setDateDue] = useState("");
	const [amount, setAmount] = useState(0);
	const [days, setDays] = useState(0);

	const clearFields = () => {
		setDateDue("");
		setAmount(0);
		setDays(0);
		setCurrentIndex(acctObj.pickupPmts.length);
	};

	useEffect(() => {
		if (currentIndex < acctObj.pickupPmts?.length) {
			setDateDue(acctObj.pickupPmts[currentIndex]?.dateDue || "");
			setAmount(acctObj.pickupPmts[currentIndex]?.amount || 0);
			setDays(
				dayjs(acctObj.pickupPmts[currentIndex]?.dateDue).diff(
					acctObj.account.loanDate,
					"day"
				)
			);
			return;
		} else {
			clearFields();
			return;
		}
		// eslint-disable-next-line
	}, [currentIndex]);

	const addPickupPayment = () => {
		// const getID = () => {
		// 	if (pickupPmts[currentIndex] === undefined) {
		// 		return null;
		// 	} else if (pickupPmts[currentIndex].ID === undefined) {
		// 		return null;
		// 	} else {
		// 		return pickupPmts[currentIndex].ID;
		// 	}
		// };
		// const ID = getID();
		const newPickup = {
			dateDue,
			amount: parseFloat(amount),
			currentIndex,
			dealerID,
			locationID,
			userRecNum: userID,
		};

		const pickupList = () => {
			let obj = [...acctObj.pickupPmts]; // Create a copy of pickupPmts
			obj[currentIndex] = newPickup;
			return obj;
		};
		const newPickupObj = pickupList();
		acctObj.editPickupPmts(newPickupObj);
		clearFields();
		calculate();
		toggle();
	};
	const calculate = () => {
		acctObj.pickupPmts.forEach((key, index) => {
			let totPickups = 0;
			if (index === currentIndex) {
				totPickups = key.amount;
			}
		});
		acctObj.account.pickupTotal = acctObj.pickupPmts.reduce(
			(acc, curr) => acc + curr.amount,
			0
		);
	};
	// Define custom error messages for different error types
	setLocale({
		mixed: {
			required: "Required",
		},
		string: {
			max: "Must be ${max} characters or less",
			length: "Required format is YYYY/MM/DD",
		},
	});

	// Define rules for each input field
	const schema = yup.object().shape({
		dateDue: yup.string().required().max(50),
		amount: yup.string().required().max(50),
	});

	// Define form validation parameters
	const methods = useForm({
		reValidateMode: "onBlur",
		resolver: yupResolver(schema),
	});

	return (
		<>
			<FormProvider {...methods}>
				<Form>
					<InputWrapper
						formTitle="Add Deferred Payment"
						inputComponents={[
							<InputElement
								{...methods}
								disableValidation
								value={acctObj.account.loanDate}
								name="acctLoanDate"
								label="Loan Date"
								type="date"
								readOnly={true}
							/>,

							<IntegerInput
								disableValidation
								value={days}
								name="daysToPay"
								label="Days to Pay"
								type="number"
								onChange={(e) => {
									setDays(e),
										setDateDue(
											dayjs(acctObj.account.loanDate)
												.add(e, "day")
												.format("YYYY-MM-DD")
										);
								}}
							/>,

							<InputElement
								key="dateDue"
								{...methods}
								value={dateDue}
								onChange={(e) => setDateDue(e.target.value)}
								onBlur={(e) =>
									setDays(
										dayjs(e.target.value).diff(acctObj.account.loanDate, "day")
									)
								}
								name="dateDue"
								label="Due Date"
								type="date"
							/>,
							<CurrencyInputElement
								key="amount"
								value={amount}
								onChange={setAmount}
								name="amount"
								label="Amount"
								type="number"
							/>,
						]}
						buttons={
							<div style={{ display: "flex", justifyContent: "center" }}>
								<Button
									color="primary"
									onClick={methods.handleSubmit(addPickupPayment)}
								>
									Save
								</Button>
							</div>
						}
					/>
				</Form>
			</FormProvider>
		</>
	);
};

export default AccountAddDeferredForm;

import React, { useEffect, useState } from "react";
import AttachmentModal from "features/attachments/AttachmentModal";
import Twilio from "services/twilioService/Twilio";
import shallow from "zustand/shallow";
import MailgunEmail from "services/mailgunService/MailgunEmail";
import NoteButton from "features/notes/NoteButton";
import useCurrentAccountStore from "./AccountStore";
import { saveAccountTable } from "./AccountAPI";
import { showApiError } from "../utils/errorRoutingUtils";
import { retrieveFailAlert, saveFailAlert } from "../utils/alertUtils";
import history from "utils/createBrowserHistory";
import { MessageZone, PATHS } from "../constants/Constants";
import useRoute from "../hooks/useRoute";
import InitializeTemplateVars from "../utils/initializeTemplateVars";
import { TemplateVariableProvider } from "../context/TemplateVariableContext";
import AccountHook from "./AccountHook";
import ActionBar from "../components/ActionBar";
import { getDealDetails } from "../api/DealAPI";
import useSetDeal from "../features/deal/hooks/useSetDeal";
import { getSettings } from "../api/SettingsAPI";

const AccountActionBar = () => {
	const { account, buyer, editAccountNotes } = useCurrentAccountStore(
		(state) => state,
		shallow
	);
	const [isMobile, setIsMobile] = useState(window.innerWidth <= 1000);
	const toggleActionsModal = () => setActionsModal(!actionsModal);
	const [actionsModal, setActionsModal] = useState(false);

	const dealDetailPage = useRoute(PATHS.DEAL_DETAIL);

	const setDeal = useSetDeal();
	const [handleSaveNotes, setHandleSaveNotes] = useState(false);
	const shouldSaveNotes = () => setHandleSaveNotes(!handleSaveNotes);
	useEffect(() => {
		saveNotes();
		// eslint-disable-next-line
	}, [handleSaveNotes]);
	const saveNotes = () => {
		if (account.id != null) {
			let modifiedAccount = { ...account };
			const modifiedNotes =
				account.notes.length > 0 ? JSON.stringify(account.notes) : "";

			modifiedAccount.notes = modifiedNotes;
			saveAccountTable(modifiedAccount).then(
				(response) => {},
				(err) => {
					if (!err.isGeneralError) {
						showApiError(err, saveFailAlert);
					}
				}
			);
		}
	};
	// const { editID } = useCurrentDealStore((state) => state, shallow);
	// Gets the deal data and routes to detail page
	const getDealData = (id) => {
		Promise.all([
			getSettings(account.dealerID, account.locationID),
			getDealDetails(id),
		])
			.then((res) => {
				setDeal(res[1].data.content);

				history.push(dealDetailPage, { ID: id });
			})
			.catch((error) => showApiError(error, retrieveFailAlert));
	};
	const save = AccountHook();
	return (
		<>
			<ActionBar
				isMobile={isMobile}
				toggleActionsModal={toggleActionsModal}
				actionsModal={actionsModal}
				modalTitle="BHPH Actions"
			>
				<div className="action-bar content">
					<TemplateVariableProvider>
						<InitializeTemplateVars messageZone={MessageZone.ACCOUNT}>
							<Twilio sendToNumber={buyer.cellPhone} personType={"account"} />
							<MailgunEmail customerEmail={buyer.email} />
						</InitializeTemplateVars>
					</TemplateVariableProvider>
					<AttachmentModal relatedID={account.id} type="account" />
					<NoteButton
						notes={account.notes}
						editNotes={editAccountNotes}
						saveNotes={shouldSaveNotes}
					/>

					<button
						className="btn btn-primary"
						onClick={() => getDealData(account.dealRecNum)}
					>
						Deal Details
					</button>
				</div>
			</ActionBar>
		</>
	);
};

export default AccountActionBar;

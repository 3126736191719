import React from "react";
import { Col, Row, Table, Form } from "reactstrap";
import { CurrencyDisplayElement } from "../features/deal/components/DisplayElement";
import dayjs from "dayjs";
import shallow from "zustand/shallow";
import useCurrentAccountStore from "./AccountStore";
import Card from "reactstrap/es/Card";
import CardBody from "reactstrap/es/CardBody";

const AccountDeferredTable = () => {
	const { pickupPmts } = useCurrentAccountStore((state) => state, shallow);
	const pickupPmtsHead = [
		"#",
		"date Due",
		"amount",
		"date Paid",
		"amount Paid",
		"receipt #",
	];
	const tableHead = pickupPmtsHead.map((key) => <th>{key}</th>);
	const rows = pickupPmts.map((key, index) => (
		<tr key={index}>
			<th scope="row">{index + 1}</th>
			<td>{dayjs(key.dateDue).format("MM/DD/YYYY")}</td>
			<td>{<CurrencyDisplayElement val={key.amount} />}</td>
			<td>{dayjs(key.datePaid || "").format("MM/DD/YYYY")}</td>
			<td>{<CurrencyDisplayElement val={key.amountPaid} />}</td>
			<td>{key.receiptNo}</td>
		</tr>
	));
	return (
		<>
			<Form>
				<Col className="pb-3 px-0 d-flex align-items-center justify-content-center">
					<div>
						<label></label>
						<h3>Deferred</h3>
					</div>
				</Col>

				<Card>
					<CardBody>
						<Row style={{ overflowX: "auto" }}>
							<Table striped bordered hover>
								<thead>
									<tr>{tableHead}</tr>
								</thead>
								<tbody>{rows}</tbody>
							</Table>
						</Row>
					</CardBody>
				</Card>
			</Form>
		</>
	);
};

export default AccountDeferredTable;

/*!

=========================================================
* DealerClick 2.0 - v1.0
=========================================================

* Product Page: https://dealerclick.com
* Copyright 2020  (https://dealerclick.com)

* Coded by DealerClick Software ))

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { useState, useEffect } from "react";
import { showApiError } from "../utils/errorRoutingUtils";
import { retrieveFailAlert } from "../utils/alertUtils";
import { Card, Row, Col, CardTitle } from "reactstrap";
import { useFromUser } from "stores/LocalStorageHelper";
import CardContent from "../components/card/CardContent";
import CardContentBottom from "../components/card/CardContentBottom";
import { getAccountStatusList } from "./AccountAPI";

const AccountHeader = ({ statusList, setStatusList }) => {
	const [totalAccount, setTotalAccount] = useState([]);
	//const [data, setData] = useState([]);
	const locationID = useFromUser("locationID");

	const [open, setOpen] = useState(0);
	const [closed, setClosed] = useState(0);

	const [status, setStatus] = useState(0);

	const dataFormat = (content, setHook) => {
		var total = 0;
		const container = Object.keys(content).map((key) => {
			total = total + content[key].numOfType;
			if (content[key].typeDesc === "Open") {
				setOpen(content[key].numOfType);
			}
			if (content[key].typeDesc === "Close") {
				setClosed(content[key].numOfType);
			}
			// if (content[key].typeDesc === "Deleted") {
			// 	setDeleted(content[key].numOfType);
			// }
			// if (content[key].typeDesc === "Repo") {
			// 	setRepo(content[key].numOfType);
			// }

			return (
				<div>
					{content[key].typeDesc} : {content[key].numOfType}
				</div>
			);
		});
		setHook(total);
		return container;
	};
	const getListData = (LocationID) => {
		if (locationID !== "") {
			getAccountStatusList(LocationID).then(
				(response) => {
					dataFormat(response.data.content, setTotalAccount);
				},
				(err) => {
					if (!err.isGeneralError) {
						showApiError(err, retrieveFailAlert);
					}
				}
			);
		}
	};
	useEffect(() => {
		getListData(locationID);
		//eslint-disable-next-line
	}, []);

	return (
		<>
			<div className="pt-3 pb-3 mobileHeading">
				<Col className="text-center">
					<CardTitle>
						<h2>Account - {status}</h2>
					</CardTitle>
				</Col>
			</div>
			<Row className="mx-0 mb-3">
				{/* Card 1 */}
				<Col
					lg="3"
					md="6"
					sm="6"
					onClick={() => {
						setStatusList(0);
						setStatus(0);
					}}
				>
					<Card className={status === 0 ? "card-stats-active" : "card-stats"}>
						{/* <NavLink to="/account-list" className="text-dark"> */}
						<CardContent
							iconClass="icon-big text-center nc-icon nc-briefcase-24 text-warning"
							title1="Open"
							title2={open}
							check={status === 0}
						/>
						{/* </NavLink> */}
						<CardContentBottom
							navClass="col-xs-3"
							routePath="/account-list"
							iconClass="nc-icon nc-minimal-down"
							iconText=" Add new"
						/>
					</Card>
				</Col>

				{/* Card 2 */}
				<Col
					lg="3"
					md="6"
					sm="6"
					onClick={() => {
						setStatusList(1);
						setStatus(1);
					}}
				>
					<Card className={status === 1 ? "card-stats-active" : "card-stats"}>
						{/* <NavLink to="/account-list" className="text-dark"> */}
						<CardContent
							iconClass="icon-big text-center nc-icon nc-briefcase-24 text-success"
							title1="Closed"
							title2={closed}
							check={status !== 0}
						/>
						{/* </NavLink> */}
						<CardContentBottom
							navClass="col-xs-3"
							routePath="/account-list"
							iconClass="nc-icon nc-simple-add"
							iconText=" Sold Report"
						/>
					</Card>
				</Col>

				{/* Card 3 */}
				{/* <Col
					lg="3"
					md="6"
					sm="6"
					onClick={() => {
						setStatusList("Funded");
						setStatus("Funded");
					}}
				>
					<Card
						className={status === "Funded" ? "card-stats-active" : "card-stats"}
					>
						<NavLink
							to="/account-list"
							activeClassName=""
							className="text-dark"
						>
							<CardContent
								iconClass="icon-big text-center nc-icon nc-briefcase-24 text-info"
								title1="Funded"
								title2={Funded}
								check={status === "Funded"}
							/>
						</NavLink>
						<CardContentBottom
							navClass="col-xs-3"
							routePath="/account-list"
							iconClass="nc-icon nc-minimal-down"
							iconText=" Funded Report"
						/>
					</Card>
				</Col> */}

				{/* Card 4 */}
				{/* <Col
					lg="3"
					md="6"
					sm="6"
					onClick={() => {
						setStatusList("Deleted,Repo");
						setStatus("Deleted & Repo");
					}}
				>
					<Card
						className={
							status === "Deleted & Repo" ? "card-stats-active" : "card-stats"
						}
					>
						<NavLink
							to="/account-list"
							activeClassName=""
							className="text-dark"
						>
							<CardContent
								iconClass="icon-big text-center nc-icon nc-briefcase-24 text-danger"
								title1="Deleted"
								title2={deleted + repo}
								check={status === "Deleted & Repo"}
							/>
						</NavLink>
						<CardContentBottom
							navClass="col-xs-3"
							routePath="/account-list"
							iconClass="nc-icon nc-minimal-down"
							iconText=" Deleted Report"
						/>
					</Card>
				</Col>  */}
			</Row>
		</>
	);
};

export default AccountHeader;

import React from "react";
import shallow from "zustand/shallow";
import useCurrentAccountStore from "./AccountStore";
import DetailPageHeader from "../components/DetailPageHeader";

const AccountTitle = () => {
	const { account } = useCurrentAccountStore((state) => state, shallow);

	const mainTitle = (
		<div>
			<span style={{ color: "black" }}># </span>
			<span style={{ color: "green", fontWeight: "bold" }}>
				{account?.accountNumber || "N/A"}
			</span>
			{account?.subStatus && (
				<>
					{" ("}
					<span
						style={{
							color: account.subStatus === "Current" ? "green" : "red",
						}}
					>
						{account.subStatus}
					</span>
					{")"}
				</>
			)}
			{/* Additional information can be included as needed */}
		</div>
	);

	return <DetailPageHeader mainTitle={mainTitle} />;
};

export default AccountTitle;
